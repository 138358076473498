/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import cx from 'classnames';
import { ListOptions } from '@/features/productListingPage';
import { pageTypes } from '@/constants/page';
import { getCartridgeType } from '@/utils/getCartridgeType';
import { CampaignModal } from '@/features/ymme/components/CampaignModal';
import ContainOverflow from '@/components/AZCustomComponent/ContainOverflow';
import DeviceWrapper from '@/components/AZCustomComponent/DeviceWrapper';
import GridContent from '@/components/ContentBlocks/MobileGridContent';
import HeroPrebackedGeneric from '@/components/PageTemplates/Generic/HeroPreBakedGeneric';
import SplitContentBlockGeneric from '@/components/PageTemplates/Generic/SplitContentBlockGeneric';
import ImageTextBlock from '@/components/ContentBlocks/ImageTextBlock/imageTextBlock';
import Related from '@/components/AZCustomComponent/Related';
import { CertonaBlock } from '@/features/certona';
import type { CertonaRecommendation } from '@/features/certona';
import HtmlBlock from '@/components/ContentBlocks/HtmlBlock/HtmlBlock';
import LiveTextBanner from '@/components/ContentBlocks/ShelfBanner/LiveTextBanner';
import RewardsBanner from '@/components/ContentBlocks/ShelfBanner/RewardsBanner';
import { FilterDesktop } from '@/components/Filter/FilterDesktop';
import Hidden from '@/components/Hidden';
import ProductFilter from '@/components/ProductRefinements/ProductFilter';
import ProductFinder from '@/components/ProductRefinements/ProductFinder';
import ShopByPaginationStyleLinks from '@/components/ShopByPaginationStyleLinks';
import { YMMEContentBlock } from '@/features/ymme/components/YMMEContentBlock';
import ShelfBannerPreBakedGeneric from '@/components/PageTemplates/Generic/ShelfBannerPreBakedGeneric';
import { FilterDataType, HandleFilterType } from '@/types/filterTypes';
import { CopyBlock } from '../BrandShelf/CopyBlock/CopyBlock';
import { ResultsList } from './ResultsList';
import styles from './styles.module.scss';
import { HeroComponentStack } from '@/features/home';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { ShopByModelList } from '@/features/shopByModel';
import type { ShelfPageBodyProductFinder, ShelfPart, SortingOption } from '@/types/legacy';
import { useProductList } from '../../../context/useProductList';
import { useCategoryYears } from '@/features/category/api/getCategoryYears';
import { useProductShelfResults } from '@/features/category/api/getProductShelfResults';
import { useRedirectAfterVehicleChanged } from '@/features/category/hooks/useRedirectAfterVehicleChanged';
import { usePageType } from '@/hooks/usePageType';
import { Ref } from 'react';
import { VideoContentBlock } from '@/components/ContentBlocks/VideoContentBlock';
import { RichTextContentBlock } from '@/components/ContentBlocks/RichTextContentBlock';
import SplitContentBlockV2Generic from '@/components/PageTemplates/Generic/SplitContentBlockV2Generic';
import { useBrandProductData } from '@/features/brands/api/getProductBrandData';
import { useIsMobileQRExperience } from '@/hooks/useIsMobileQRExperience';
import type {
  ContentBlock,
  DeviceOptions,
  ShelfBannerPreBaked as ShelfBanner,
} from '@/types/legacy';
import { isContentBlockSlot } from '@/utils/isContentBlockSlot';
import type { CampaignData } from '@/features/ymme';
import type { RelatedPartTypes as PartTypes } from '@/components/AZCustomComponent/Related/types';
import dynamic from 'next/dynamic';
import { useIs24ProductViewEnabled } from '@/hooks/useIs24ProductViewEnabled';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { NumberOfResults } from '@/features/search';
import { countryCodes } from '@/constants/locale';
import { useMonetateDecisionFlag } from '@/features/kibo';

const LazyYearsTable = dynamic(
  () => import('../../YearsTable/YearsTable').then(({ YearsTable }) => ({ default: YearsTable })),
  { ssr: true }
);

export type Content = {
  '@type': string;
} & Omit<ShelfContentBlock, 'contentType'>;

export type ShelfContentBlock =
  | Exclude<ContentBlock, ShelfBanner>
  | CopyBlock
  | FilterNav
  | CampaignModal
  | SortingOptionsContainer
  | ResultsList
  | ShelfPageBodyProductFinder
  | Certona
  | RelatedPartTypes
  | ImageBannerPreBaked
  | ShelfBannerPreBaked
  | SMMY
  | HTMLBlock
  | SMMYPaginationStyleLinks;

export type CopyBlock = {
  '@type': string;
  contentType: 'CopyBlock';
  content: string;
} & DeviceOptions;

export type HTMLBlock = {
  '@type': string;
  content: string;
  contentType: 'HTMLBlock';
} & DeviceOptions;

type FilterNav = {
  '@type': string;
  contentType: 'FilterNav';
  staticNavigation: FilterDataType[];
  dynamicNavigation?: { attributeNarrowingList: FilterDataType[] };
};

type CampaignModal = {
  '@type': string;
  contentType: 'CampaignModal';
} & CampaignData;

type SortingOptionsContainer = {
  '@type': string;
  contentType: 'SortingOptionsContainer';
  sortingOptions: SortingOption[];
  quickFilter: boolean;
};

type ResultsList = {
  '@type': string;
  contentType: 'ResultsList';
  recsPerPage: number;
  totalNumRecs: number;
  firstRecNum: number;
};

type Certona = {
  '@type': string;
  contentType: 'Certona';
  themeCode?: string | undefined;
  name: string;
  containerID?: string | undefined;
} & DeviceOptions;

type RelatedPartTypes = {
  '@type': string;
  contentType: 'RelatedPartTypes';
  desktop: boolean;
  mobile: boolean;
  name: string;
  relatedPartTypes: PartTypes;
  tablet: boolean;
};

type ImageBannerPreBaked = {
  '@type': string;
  contentType: 'ImageBannerPreBaked';
  legalLink: string;
  legalCopy: string;
  altText: string;
  imagePath: string;
  legalLinkName: string;
  legalText: string;
  imageLink: string;
  name: string;
} & DeviceOptions;

type ShelfBannerPreBaked = {
  '@type': string;
  contentType: 'ShelfBannerPreBaked';
  legalLink: string;
  legalCopy: string;
  altText: string;
  imagePath: string;
  legalLinkName: string;
  legalText: string;
  imageLink: string;
  name: string;
} & DeviceOptions;

type SMMY = {
  '@type': string;
  contentType: 'SMMY';
  shopByMMYList: Array<{
    seoUrl: string;
    name: string;
    makeModelYearPath: string;
  }>;
  title: string;
} & DeviceOptions;

type SMMYPaginationStyleLinks = {
  '@type': string;
  contentType: 'SMMYPaginationStyleLinks';
  title: string;
  shopByPaginationStyleLinks: Array<{
    name: string;
    seoUrl: string;
    taxonomyPath?: string | undefined;
    makeModelYearPath?: string | undefined;
  }>;
} & DeviceOptions;

type NotSpecifiedContentBlock = Omit<ShelfContentBlock, 'contentType'> & { contentType: string };

export type ShelfPageTemplateProps = {
  refQuickFilter?: Ref<HTMLElement>;
  ymmeRef?: Ref<HTMLDivElement>;
  content: Content[];
  certonaRecommendations?: CertonaRecommendation[];
  onFilterSelect?: (a: HandleFilterType) => void;
  handleDealClick?: (
    part: ShelfPart,
    price: string,
    quantity: number,
    ref: React.RefObject<HTMLButtonElement>,
    fulfillmentTypeId: number | null
  ) => void;
  yearsTableData?: {
    productId?: string | null;
    make?: string;
    model?: string;
    hasAssociatedPartType?: boolean;
  };
};

const ShelfPageTemplateComponent = ({
  refQuickFilter,
  ymmeRef,
  content,
  certonaRecommendations,
  onFilterSelect,
  handleDealClick,
  yearsTableData,
}: ShelfPageTemplateProps) => {
  const locale = useLocale();
  const labels = useLabels();
  const { data: shelfData } = useProductList();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const enable24ProductView = useIs24ProductViewEnabled();
  const productFilterData = shelfData?.productFilterData;
  const { pageType } = usePageType();
  const isBrandShelf = pageType === pageTypes.BrandShelf;
  const isShelfOrSearchPage =
    pageType === pageTypes.shelf ||
    pageType === pageTypes.ProductShelf ||
    pageType === pageTypes.search;
  const isUS = locale === countryCodes.us;
  const useNewShelfDesignMobile =
    useMonetateDecisionFlag('useNewShelfDesignMobile') && isMobile && isUS && isShelfOrSearchPage;
  const redirectAfterVehicleChanged = useRedirectAfterVehicleChanged();
  const { data: brandProductData } = useBrandProductData({ enabled: isBrandShelf });

  const { data: productShelfResultsData, status } = useProductShelfResults();

  const isShopByModel =
    status === 'success' &&
    productShelfResultsData.productShelfResults?.make &&
    productShelfResultsData.productShelfResults.model;

  const { data: yearsData } = useCategoryYears({
    enabled: Boolean(isShopByModel),
    year: Boolean(productShelfResultsData?.productShelfResults?.year),
  });
  const isMobileQRExperience = useIsMobileQRExperience();

  const isSpecificContentBlock = (
    contentItem: NotSpecifiedContentBlock
  ): contentItem is ShelfContentBlock => {
    return [
      'CopyBlock',
      'FilterNav',
      'CampaignModal',
      'SortingOptionsContainer',
      'ResultsList',
      'YMME',
      'ProductFinder',
      'Certona',
      'RelatedPartTypes',
      'ShelfBannerPreBaked',
      'ImageBannerPreBaked',
      'ShelfBannerLiveText',
      'Grid',
      'SplitContentBlock',
      'SplitContentBlockV2',
      'HeroPreBaked',
      'HeroCarouselSlot',
      'ImageText',
      'VideoContentBlock',
      'RichTextBlock',
      'SMMY',
      'SMMYPaginationStyleLinks',
      'HTMLBlock',
    ].includes(contentItem.contentType);
  };

  const shelfOrBrandXFusionQueryId = () => {
    return isBrandShelf
      ? brandProductData?.xFusionQueryId
      : productShelfResultsData?.xFusionQueryId;
  };

  const renderContentBlocks = (
    contentType: string,
    contentItem: NotSpecifiedContentBlock,
    i: number,
    isLastItem: boolean
  ) => {
    if (!isSpecificContentBlock(contentItem)) {
      return null;
    }

    const key = `${contentType}-${i}`;
    switch (contentItem.contentType) {
      case 'CopyBlock':
        return <CopyBlock key={key} contentItem={contentItem} />;

      case 'HTMLBlock': {
        return (
          <Hidden
            key={key}
            smDown={useNewShelfDesignMobile || !contentItem.mobile}
            mdUp={!contentItem.tablet}
            lgUp={!contentItem.desktop}
          >
            <div className={cx('htmlContentBlock', styles.htmlBlockWrapper)} key={key}>
              <ContainOverflow fullWidth key={key}>
                <HtmlBlock content={contentItem} key={key} />
              </ContainOverflow>
            </div>
          </Hidden>
        );
      }

      case 'FilterNav':
        return (
          <Hidden mdDown key={key}>
            <FilterDesktop
              staticFilterData={
                brandProductData?.staticNavigation?.map((filter) => {
                  if (filter.dimensionName === 'brand_name_fltr') {
                    filter.displayName = labels.label_brand_shelf_sub_brands ?? 'Sub Brands';
                  }
                  return filter;
                }) ?? contentItem.staticNavigation
              }
              dynamicFilterData={contentItem.dynamicNavigation?.attributeNarrowingList}
              pageType={contentItem['@type'].split('_')[0].toLowerCase()}
              onFilterSelect={onFilterSelect}
            />
          </Hidden>
        );

      case 'CampaignModal':
        return <CampaignModal key={key} campaignData={contentItem} />;

      case 'SortingOptionsContainer':
        return (
          <React.Fragment key={key}>
            <ListOptions
              refQuickFilter={refQuickFilter}
              pageType={pageTypes.shelf}
              onFilterSelect={onFilterSelect}
              disableRecordsPerPage={
                enable24ProductView &&
                (brandProductData?.total ??
                  productShelfResultsData?.productShelfResults?.totalNumberOfRecords ??
                  36) < 36
                  ? true
                  : undefined
              }
            />
            {useNewShelfDesignMobile && (
              <div className={styles.numberOfResultsContainer}>
                <NumberOfResults
                  firstPageRec={
                    brandProductData?.firstRecNum ??
                    productShelfResultsData?.productShelfResults?.firstRecordNumber ??
                    1
                  }
                  recsPerPage={
                    brandProductData?.recordsPerPage ??
                    productShelfResultsData?.productShelfResults?.recordsPerPage ??
                    12
                  }
                  totalNumRecs={
                    brandProductData?.total ??
                    productShelfResultsData?.productShelfResults?.totalNumberOfRecords ??
                    0
                  }
                />
              </div>
            )}
          </React.Fragment>
        );

      case 'ResultsList':
        return (
          <ResultsList
            locale={locale}
            key={key}
            recsPerPage={brandProductData?.recordsPerPage ?? contentItem.recsPerPage}
            totalNumRecs={brandProductData?.total ?? contentItem.totalNumRecs}
            firstRecNum={brandProductData?.firstRecNum ?? contentItem.firstRecNum}
            isPaginationEnabled={
              (brandProductData?.total ?? contentItem.totalNumRecs) >
              (brandProductData?.recordsPerPage ?? contentItem.recsPerPage)
            }
            handleDealClick={handleDealClick}
            xFusionQueryId={shelfOrBrandXFusionQueryId()}
          />
        );

      case 'YMME':
        return (
          <div ref={ymmeRef} key={key}>
            <ContainOverflow fullWidth={true}>
              <YMMEContentBlock
                onUpdateVehicleSuccess={redirectAfterVehicleChanged}
                pageType={pageTypes.shelf}
                data={contentItem}
              />
            </ContainOverflow>
            {productFilterData && (
              <DeviceWrapper
                desktop={productFilterData.desktop || false}
                tablet={productFilterData.tablet || false}
                mobile={productFilterData.mobile || false}
              >
                <ProductFilter productFilterData={productFilterData} />
              </DeviceWrapper>
            )}
          </div>
        );

      case 'ProductFinder':
        return (
          <DeviceWrapper
            desktop={contentItem.desktop || false}
            tablet={contentItem.tablet || false}
            mobile={contentItem.mobile || false}
            key={key}
          >
            <ProductFinder productFinderData={contentItem} />
          </DeviceWrapper>
        );

      case 'Certona':
        return (
          <CertonaBlock
            className={styles.fullWithContainer}
            contentItem={contentItem}
            certonaRecommendations={certonaRecommendations}
            key={key}
          />
        );

      case 'RelatedPartTypes':
        return <Related contentItem={contentItem} key={key} />;

      case 'ShelfBannerPreBaked':
      case 'ImageBannerPreBaked':
        if (isMobileQRExperience) {
          return null;
        }
        if (contentItem.name === 'rewards_shelf_banner') {
          return <RewardsBanner contentItem={contentItem} key={key} />;
        }

        return (
          <ShelfBannerPreBakedGeneric
            key={key}
            index={i}
            keyProp={key}
            contentItem={contentItem}
            contentType={contentType}
            classes={{
              root: styles.shelfBannerRoot,
            }}
          />
        );

      case 'ShelfBannerLiveText':
        return (
          <ContainOverflow key={key}>
            <LiveTextBanner contentItem={contentItem} />
          </ContainOverflow>
        );

      case 'Grid':
        return (
          <ContainOverflow key={key}>
            <GridContent contentBlock={contentItem.contentBlock} />
          </ContainOverflow>
        );

      case 'SplitContentBlock':
        return <SplitContentBlockGeneric key={key} contentItem={contentItem} />;

      case 'SplitContentBlockV2':
        return <SplitContentBlockV2Generic key={key} contentItem={contentItem} />;

      case 'HeroPreBaked':
        return <HeroPrebackedGeneric key={key} keyProp={key} contentItem={contentItem} />;

      case 'HeroCarouselSlot':
        return (
          <DeviceWrapper
            desktop={contentItem.desktop || false}
            tablet={contentItem.tablet || false}
            mobile={contentItem.mobile || false}
            key={key}
          >
            <ContainOverflow fullWidth key={key}>
              <HeroComponentStack data={contentItem.contents} key={key} />
            </ContainOverflow>
          </DeviceWrapper>
        );

      case 'ImageText':
        return (
          <ContainOverflow key={key}>
            <ImageTextBlock
              data={contentItem}
              key={key}
              addBottomMargin={isLastItem && contentItem['@type'] === 'BrandShelfHeader_ImageText'}
            />
          </ContainOverflow>
        );

      case 'VideoContentBlock':
        return (
          <ContainOverflow key={key}>
            <VideoContentBlock data={contentItem} key={key} />
          </ContainOverflow>
        );

      case 'RichTextBlock':
        return (
          <ContainOverflow key={key}>
            <RichTextContentBlock data={contentItem} />
          </ContainOverflow>
        );

      case 'SMMY':
        return (
          <React.Fragment key={key}>
            {yearsTableData && (
              <LazyYearsTable
                productId={yearsTableData.productId}
                make={yearsTableData.make}
                model={yearsTableData.model}
                hasAssociatedPartType={yearsTableData.hasAssociatedPartType}
              />
            )}
            <ShopByModelList
              data={{
                shopByMMYList: yearsData?.shopByMMYList ?? contentItem.shopByMMYList ?? [],
                title: yearsData?.title ?? contentItem.title,
                canonicalPath: yearsData?.canonicalPath,
                taxonomyPath: yearsData?.taxonomyPath,
                pageType:
                  yearsData?.pageType ?? productShelfResultsData?.productShelfResults?.pageType,
                desktop: contentItem.desktop,
                tablet: contentItem.tablet,
                mobile: contentItem.mobile,
              }}
              key={key}
            />
          </React.Fragment>
        );

      case 'SMMYPaginationStyleLinks':
        return (
          <ShopByPaginationStyleLinks
            data={{
              shopByPaginationStyleLinks:
                yearsData?.shopByMMYList ?? contentItem.shopByPaginationStyleLinks ?? [],
              canonicalPath: yearsData?.canonicalPath,
              taxonomyPath: yearsData?.taxonomyPath,
              pageType:
                yearsData?.pageType ?? productShelfResultsData?.productShelfResults?.pageType,
              desktop: contentItem.desktop,
              tablet: contentItem.tablet,
              mobile: contentItem.mobile,
              title: yearsData?.title ?? '',
            }}
            key={key}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      {content.map((contentItem, i) => {
        const contentType = getCartridgeType(contentItem['@type']);

        if (contentType === 'Slot' && isContentBlockSlot(contentItem)) {
          return contentItem.contents.map((singleContentItem, mappedContentIndex: number) =>
            renderContentBlocks(
              getCartridgeType(singleContentItem['@type']),
              { ...singleContentItem, contentType: getCartridgeType(singleContentItem['@type']) },
              mappedContentIndex,
              i === content.length - 1 && mappedContentIndex === contentItem.contents.length
            )
          );
        }
        return renderContentBlocks(
          contentType,
          { ...contentItem, contentType: contentType },
          i,
          i === content.length - 1
        );
      })}
    </>
  );
};

export const ShelfPageTemplate = React.memo(ShelfPageTemplateComponent);
