/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { FilterDesktop } from '@/components/Filter/FilterDesktop';
import { pageTypes } from '@/constants/page';
import { useBrandProductData } from '@/features/brands/api/getProductBrandData';
import { useLabels } from '@/hooks/useLabels';
import type { ShelfLeftNav } from '@/types/legacy';
import { usePageType } from '@/hooks/usePageType';
import { HandleFilterType } from '@/types/filterTypes';
import { useMemo } from 'react';

export type Props = {
  content: ShelfLeftNav;
  onFilterSelect?: (a: HandleFilterType) => void;
};

const CMSShelfLeftNav = ({ content, onFilterSelect }: Props) => {
  const { pageType } = usePageType();
  const labels = useLabels();
  const isBrandShelf = pageType === pageTypes.BrandShelf;
  const { data: brandProductData } = useBrandProductData({ enabled: isBrandShelf });

  const { staticFilterData, dynamicFilterData } = useMemo(() => {
    const filterNav = content.find(
      (item) => item['@type'] === 'ShelfLeftNav_FilterNav'
    ) as ShelfLeftNav[0];

    const dynamicFilterData = filterNav?.dynamicNavigation?.attributeNarrowingList;

    if (!brandProductData?.staticNavigation) {
      return { staticFilterData: filterNav.staticNavigation, dynamicFilterData };
    }

    const staticFilterData = brandProductData.staticNavigation.map((filter) => {
      const filterData = { ...filter };
      if (filter.dimensionName === 'brand_name_fltr') {
        filterData.displayName = labels.label_brand_shelf_sub_brands;
      }
      return filterData;
    });

    return {
      staticFilterData,
      dynamicFilterData,
    };
  }, [brandProductData?.staticNavigation, content, labels.label_brand_shelf_sub_brands]);

  return (
    <FilterDesktop
      staticFilterData={staticFilterData}
      dynamicFilterData={dynamicFilterData}
      pageType={pageType ?? pageTypes.productShelf}
      onFilterSelect={onFilterSelect}
    />
  );
};

export { CMSShelfLeftNav };
