/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Grid } from '@/components/Grid';
import type { ShelfPageBodyItemGeneric, ShelfPageBodyComplete } from '@/types/legacy';
import Breadcrumbs from '@/components/AZCustomComponent/BreadcrumbSTARC';
import ShelfHeading from '@/components/Heading-filter';
import azCommonStyles from '@/theme/globals.module.scss';
import Title from '@/components/AZCustomComponent/Title';
import { ShelfPageTemplate } from '../PageTemplates/Shelf';
import styles from './styles.module.scss';
import { type IItem } from '@/components/AZCustomComponent/BreadcrumbSTARC/utils/isBreadcrumbModel';
import { useRouter } from 'next/router';
import { ExpandableTopDescription } from './ExpandableTopDescription';
import { View, Text, ArrowLeft, Icon } from '@az/starc-ui';
import { useRemoveUnsupportedSearchChars } from '@/hooks/useRemoveUnsupportedSearchChars';
import { useLabel } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { SmartLink } from '@/utils/smartLink';
import { usePageType } from '@/hooks/usePageType';
import { pageTypes } from '@/constants/page';
import { countryCodes } from '@/constants/locale';
import { useMonetateDecisionFlag } from '@/features/kibo';
import { useBrandProductData } from '@/features/brands/api/getProductBrandData';

type Props = {
  shelfPart?: ShelfPageBodyComplete | [];
  catLabel?: string;
  shelfResultHeader: ShelfPageBodyItemGeneric[] | [] | undefined;
  shelfBreadcrumb: IItem[] | undefined;
  makeModelYearPageType?: string | null;
  shortDescription: string | null | undefined;
  brandPageHeader?: string;
  seoTopCopy?: string | null;
  showBackButton: boolean | undefined;
};

const ShelfTopNav = (props: Props) => {
  const {
    shelfPart,
    catLabel = '',
    shelfBreadcrumb,
    shelfResultHeader = [],
    makeModelYearPageType,
    shortDescription,
    seoTopCopy,
    showBackButton = false,
  } = props;
  const locale = useLocale();
  const router = useRouter();
  const shelfPartValue = shelfPart?.[5] || {};
  const { pageType } = usePageType();
  const isBrandPage = pageType === pageTypes.BrandShelf;
  const { data: brandData } = useBrandProductData({
    enabled: isBrandPage,
  });
  const isShelfOrSearchPage =
    pageType === pageTypes.shelf ||
    pageType === pageTypes.ProductShelf ||
    pageType === pageTypes.search ||
    pageType === pageTypes.MerchShelf;
  const headerText =
    catLabel || shelfPart?.find((item) => item.name === 'Results List')?.headerText || '';
  const catLabelValue =
    isBrandPage && brandData?.brandName ? `${brandData?.brandName} Parts and Products` : headerText;
  const listOfBreadCrumb = shelfBreadcrumb ? shelfBreadcrumb : [];
  const shelfHeaderContentExists = !!shelfResultHeader.length;
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const backButtonLabel = useLabel('label_back_to_results');
  const removeUnsupportedSearchChars = useRemoveUnsupportedSearchChars();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isUS = locale === countryCodes.us;
  const useNewShelfDesignMobile =
    useMonetateDecisionFlag('useNewShelfDesignMobile') && isMobile && isUS && isShelfOrSearchPage;

  const renderTitleBeforeHeaderContent = shelfHeaderContentExists && isBrandPage;

  return (
    <div>
      <div
        className={cx({
          [styles.breadcrumbsAndBackBtnContainer]:
            useNewShelfDesignMobile && listOfBreadCrumb.length > 0,
        })}
      >
        <Breadcrumbs
          customClass={cx({
            [styles.showingBack]: showBackButton && !isMobile,
            [styles.slimBreadcrumbs]: useNewShelfDesignMobile && listOfBreadCrumb.length > 0,
          })}
          showSEOData={!makeModelYearPageType}
          data={listOfBreadCrumb || []}
        />
        {showBackButton && (
          <SmartLink
            samePage
            to={`/searchresult?searchText=${encodeURIComponent(
              removeUnsupportedSearchChars(String(router.query.searchText))
            )}`}
          >
            <View
              align="center"
              gap={useNewShelfDesignMobile ? 2 : 1}
              direction="row"
              padding={[2, !isLargeScreen ? 3 : 0]}
            >
              <Icon svg={ArrowLeft} color="accent" />
              <Text
                // TODO: remove className when STARC resolves https://gitlab.autozone.com/it/open-interface/starc/-/issues/27
                className={cx(styles.fontFamCond, {
                  [styles.slimBackLink]: useNewShelfDesignMobile,
                })}
                weight="medium"
                attributes={{ style: { lineHeight: useNewShelfDesignMobile ? '24px' : '1.40' } }}
                size={useNewShelfDesignMobile ? '087' : '125'}
                textCase="uppercase"
              >
                {backButtonLabel}
              </Text>
            </View>
          </SmartLink>
        )}
      </div>
      {renderTitleBeforeHeaderContent && (
        <Title
          overRuleH1="h1"
          variant="lg"
          firstText={catLabelValue}
          stylesClass={styles.brandShelfTitle}
          firstTextClass={styles.brandShelfHeading}
          dataTestIdContainer="custom-header-container"
          id="shelfHeader"
        />
      )}
      {shelfHeaderContentExists ? <ShelfPageTemplate content={shelfResultHeader} /> : null}

      <ShelfHeading data={shelfPartValue} catLabel={catLabelValue} />
      {!renderTitleBeforeHeaderContent && catLabelValue && (
        <Grid
          container
          spacing={0}
          className={cx(styles.titleSection, {
            [styles.slimTitleSection]: useNewShelfDesignMobile,
          })}
          wrap={isLargeScreen ? 'wrap' : 'nowrap'}
        >
          <Grid item sm={12} md={10} lg={9}>
            <Title
              overRuleH1="h1"
              variant="lg"
              firstText={catLabelValue}
              firstTextClass={cx(styles.shelfTitle, {
                [styles.slimTitleText]: useNewShelfDesignMobile,
              })}
              dataTestIdContainer="custom-header-container"
              id="shelfHeader"
            />
            {shortDescription && seoTopCopy === null && (
              <p className={cx(azCommonStyles['az-body-2-regular'], styles.shortDescription)}>
                {shortDescription}
              </p>
            )}
            {seoTopCopy && <ExpandableTopDescription content={seoTopCopy ? seoTopCopy : ''} />}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ShelfTopNav;
