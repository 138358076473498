/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { useLabel } from '@/hooks/useLabels';
import DeviceWrapper from '../../AZCustomComponent/DeviceWrapper';
import styles from './styles.module.scss';
import RelatedColum from './RelatedColum';
import type { RelatedProps } from './types';

function Related({ contentItem: { relatedPartTypes, desktop, tablet, mobile } }: RelatedProps) {
  const { relatedParts, relatedProducts } = relatedPartTypes;
  const relatedPartsLabel = useLabel('related_parts_title_parts');
  const relatedProductsLabel = useLabel('related_parts_title_products');

  return (
    <DeviceWrapper desktop={desktop || false} tablet={tablet || false} mobile={mobile || false}>
      <div className={styles.relatedContainer}>
        {relatedParts && relatedParts.length > 0 && (
          <RelatedColum nameOfTheColum={relatedPartsLabel} relatedColumItem={relatedParts} />
        )}
        {relatedProducts && relatedProducts.length > 0 && (
          <RelatedColum nameOfTheColum={relatedProductsLabel} relatedColumItem={relatedProducts} />
        )}
      </div>
    </DeviceWrapper>
  );
}

export default Related;
