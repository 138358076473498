/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useSelector } from 'react-redux';
import DeviceWrapper from '../../AZCustomComponent/DeviceWrapper';
import NextImage from '@/components/NextImage';
import { rightArrowActive } from '../../../constants/images/rightArrowActive';
import { useLabel } from '@/hooks/useLabels';
import { SmartLink as Link } from '../../../utils/smartLink';
import { interpolateLabel } from '../../../utils/labels/interpolateLabel';
import { ReduxState } from '../../../types';
import styles from './styles.module.scss';
import { useRewardsActivity } from '@/features/rewards/api/getRewardsActivity';

type Props = {
  contentItem: {
    legalLink: string;
    legalCopy: string;
    altText: string;
    imagePath: string;
    legalLinkName: string;
    mobile: boolean;
    legalText: string;
    tablet: boolean;
    imageLink: string;
    desktop: boolean;
    name: string;
  };
  styleOverride?: any;
};

const RewardsBanner = ({ contentItem, styleOverride }: Props) => {
  const { mobile, tablet, desktop } = contentItem;
  const lblRewardsAreWaiting = useLabel('label_Shelf_RewardsBanner_RewardsAreWaiting');
  const lblEveryPurchaseEarnCredit = useLabel(
    'label_Shelf_RewardsBanner_lblEveryPurchaseEarnCredit'
  );
  const lblSignInJoinToday = useLabel('label_Shelf_RewardsBanner_lblSignInJoinToday');
  const lblEarnCreditsForReward = useLabel('label_Shelf_RewardsBanner_lblEarnCreditsForReward');
  const lblJoinToday = useLabel('label_Shelf_RewardsBanner_lblJoinToday');
  const lblMyAccount = useLabel('label_Shelf_RewardsBanner_lblMyAccount');
  const lblRewardsAvailable = useLabel('label_Shelf_RewardsBanner_lblRewardsAvailable');
  const lblCreditsEarned = useLabel('label_Shelf_RewardsBanner_lblCreditsEarned');
  const isLoggedIn = useSelector<ReduxState>(({ appData }) => appData.userAuthenticated);
  const { data: rewardsData } = useRewardsActivity();
  const currentRewardsBalance = rewardsData?.loyaltyAccount.rewardBalance;
  const currentCreditsBalance = rewardsData?.loyaltyAccount.currentNumberOfCredits;
  const buttonLinkPath = isLoggedIn ? '/user/myAccount' : '/signin';
  /* Signed out */

  let rewardsTitle = lblRewardsAreWaiting;
  let rewardsText = lblEveryPurchaseEarnCredit;
  let rewardsButtonText = lblSignInJoinToday;

  if (isLoggedIn) {
    rewardsButtonText = lblMyAccount;

    if (rewardsData?.loyaltyAccount.loyaltyCardNumber === null) {
      /* No Rewards account */
      rewardsTitle = lblRewardsAreWaiting;
      rewardsText = lblEarnCreditsForReward;
      rewardsButtonText = lblJoinToday;
    } else {
      if (currentCreditsBalance === 0 && currentRewardsBalance && currentRewardsBalance > 0) {
        /* Rewards Only */
        rewardsTitle = interpolateLabel(lblRewardsAvailable, {
          balance: currentRewardsBalance,
        });
        rewardsText = '';
      } else if (
        currentCreditsBalance &&
        currentCreditsBalance > 0 &&
        currentRewardsBalance === 0
      ) {
        /* Credits Only */
        rewardsTitle = interpolateLabel(lblCreditsEarned, {
          balance: currentCreditsBalance,
        });
        rewardsText = lblEveryPurchaseEarnCredit;
      } else if (
        currentCreditsBalance &&
        currentCreditsBalance > 0 &&
        currentRewardsBalance &&
        currentRewardsBalance > 0
      ) {
        /* Credits and Rewards */
        rewardsTitle = interpolateLabel(lblRewardsAvailable, {
          balance: currentRewardsBalance,
        });
        rewardsText = interpolateLabel(lblCreditsEarned, {
          balance: currentCreditsBalance,
        });
      } else {
        /* None */
        rewardsTitle = lblRewardsAreWaiting;
        rewardsText = lblEarnCreditsForReward;
      }
    }
  }

  return (
    <DeviceWrapper desktop={desktop || false} tablet={tablet || false} mobile={mobile || false}>
      <div style={styleOverride ? styleOverride : null}>
        <div className={styles.rewardsBannerCard}>
          <NextImage src="/images/featuredicons-rewards.svg" height={36} width={36} />
          <div className={styles.rewardsBannerContent}>
            <p className={styles.rewardsBannerTitle}>{rewardsTitle}</p>
            <p className={styles.rewardsBannerText}>{rewardsText}</p>
          </div>
          <div className={styles.rewardsBannerBG} />
          <Link className={styles.rewardsBannerButton} to={buttonLinkPath}>
            <div className={styles.rewardsBannerButtonText}>{rewardsButtonText}</div>
            <NextImage src={rightArrowActive.src} width={16} height={16} />
          </Link>
        </div>
      </div>
    </DeviceWrapper>
  );
};

export default RewardsBanner;
