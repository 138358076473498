/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { Grid } from '../../Grid';
import type { Props as GridProps } from '../../Grid';
import azCommonStyles from '../../../theme/globals.module.scss';
import styles from './styles.module.scss';
import NextImage from '@/components/NextImage';
import Hidden from '../../Hidden';

type ProductRefinementWrapperClasses = {
  description?: string;
  header?: string;
  rightSide?: string;
  root?: string;
};

type ProductRefinementWrapperProps = {
  backgroundImage: string;
  children: React.ReactNode;
  classes?: ProductRefinementWrapperClasses;
  containerProps?: GridProps;
  description: string;
  header: string;
  isTopHeader?: boolean;
  leftSideProps?: GridProps;
  rightSideProps?: GridProps;
  subheader?: string;
  warningMessage?: string;
};

const ProductRefinementWrapper = (props: ProductRefinementWrapperProps) => {
  const {
    backgroundImage,
    children,
    classes = {},
    containerProps,
    description,
    header,
    leftSideProps,
    rightSideProps,
    subheader,
    isTopHeader,
    warningMessage,
  } = props;
  const backgroundIconStyle = backgroundImage
    ? {
        backgroundImage: `url(${backgroundImage})`,
      }
    : {};
  let {
    root: rootClass,
    description: descriptionClass,
    header: headerClass,
    rightSide: rightSideClass,
  } = classes;
  rootClass = rootClass
    ? cx(styles.productRefinementWrapper, rootClass)
    : styles.productRefinementWrapper;
  descriptionClass = descriptionClass
    ? cx(styles.description, descriptionClass)
    : styles.description;
  headerClass = headerClass ? cx(styles.header, headerClass) : styles.header;
  rightSideClass = rightSideClass ? cx(styles.rightSide, rightSideClass) : styles.rightSide;
  const showProductFinderWarningMessage = !!warningMessage?.trim();
  return (
    <Grid
      container
      spacing={0}
      alignItems="center"
      className={rootClass}
      data-testid="product-refinement-wrapper"
      style={backgroundIconStyle}
      {...containerProps}
    >
      <div className={styles.line} aria-hidden={true}></div>
      {isTopHeader && (
        <h2 className={headerClass} data-testid="top-header">
          {header}
        </h2>
      )}
      <Grid item xs={12} md={6} lg={7} className={styles.leftSide} {...leftSideProps}>
        {!isTopHeader && (
          <h2 className={headerClass} data-testid="header">
            {header}
          </h2>
        )}
        {subheader && (
          <h3
            data-testid="product-refinement-subheader"
            className={cx(azCommonStyles['az-body-1-heavy'], styles.subheader)}
          >
            {subheader}
          </h3>
        )}
        <div
          data-testid="product-refinement-desc"
          className={descriptionClass}
          dangerouslySetInnerHTML={(() => ({
            __html: description,
          }))()}
        />
        {showProductFinderWarningMessage && (
          <Hidden smDown>
            <div className={styles.warningMessageContainer} data-testid="warningMessage">
              <div className={styles.warningIconContainer}>
                <NextImage
                  className={styles.warningIcon}
                  src="/images/info_orderSummary.svg"
                  alt="info icon"
                  width={15}
                  height={15}
                />
              </div>
              <aside className={styles.warningText}>{warningMessage}</aside>
            </div>
          </Hidden>
        )}
      </Grid>
      <Grid
        item
        data-testid="right-side-container"
        xs={12}
        md={6}
        lg={5}
        className={rightSideClass}
        {...rightSideProps}
      >
        {children}
        {showProductFinderWarningMessage && (
          <Hidden mdUp>
            <div className={styles.warningMessageContainer} data-testid="warningMessageSmall">
              <div className={styles.warningIconContainer}>
                <NextImage
                  className={styles.warningIcon}
                  src="/images/info_orderSummary.svg"
                  alt="info icon small"
                  width={15}
                  height={15}
                />
              </div>
              <aside className={styles.warningText}>{warningMessage}</aside>
            </div>
          </Hidden>
        )}
      </Grid>
    </Grid>
  );
};

export default ProductRefinementWrapper;
