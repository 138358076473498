/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { certonaPageType } from '@/constants/certonaPageType';

export function getShelfPageType(taxonomyId: string | undefined) {
  const taxonomyParts = taxonomyId?.split('_');
  const firstPart = taxonomyParts?.[1];
  if (firstPart?.endsWith('-0')) {
    return certonaPageType.partsLandingEvent;
  } else if (firstPart?.endsWith('-10') || firstPart?.startsWith('cat')) {
    return certonaPageType.accessoriesCategoryLandingPage;
  }
}
