/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import NextImage from '@/components/NextImage';
import ProductRefinementWrapper from '../ProductRefinementWrapper';
import { useEagerLoad } from '../../../hooks/useEagerLoad';
import InfoToolTip from '../../AZCustomComponent/InfoToolTip';
import styles from './styles.module.scss';
import { usePageType } from '@/features/category/api/getPageType';
import { getShopByPageName } from '@/features/category/utils/getShopByPageName';

type ProductFinderProps = {
  productFinderData: {
    ProductFinder?: Array<Record<string, string | null>>;
    description?: string | undefined;
    header?: string | undefined;
    backgroundImage?: string;
    warningMessage?: string;
    isEagerLoadDesktop?: boolean;
    isEagerLoadMobile?: boolean;
    isEagerLoadTablet?: boolean;
    imageColumn1?: string | undefined;
    imageColumn2?: string | undefined;
    imageColumn3?: string | undefined;
    tooltipColumnHeader1?: string | undefined;
    tooltipColumnHeader2?: string | undefined;
    tooltipColumnHeader3?: string | undefined;
    tooltipColumnContent1?: string | undefined;
    tooltipColumnContent2?: string | undefined;
    tooltipColumnContent3?: string | undefined;
  };
};

const ProductFinder = ({ productFinderData }: ProductFinderProps) => {
  const {
    ProductFinder: productFinderSections,
    description,
    header,
    backgroundImage,
    warningMessage,
    isEagerLoadDesktop,
    isEagerLoadMobile,
    isEagerLoadTablet,
  } = productFinderData;
  const { data: pageTypeData } = usePageType();
  const makeModelYearPageType = getShopByPageName(pageTypeData);
  const eager = useEagerLoad({
    isEagerLoadDesktop,
    isEagerLoadMobile,
    isEagerLoadTablet,
  });
  const productFinderLength = productFinderSections?.length;

  return productFinderLength && !makeModelYearPageType ? (
    <ProductRefinementWrapper
      backgroundImage={backgroundImage ?? ''}
      description={description ?? ''}
      header={header ?? ''}
      warningMessage={warningMessage}
    >
      <ul className={styles.productFinderDetailsList}>
        {productFinderSections.map((entry: any, index: number) => {
          const imageColumn =
            `imageColumn${index + 1}` in productFinderData
              ? (productFinderData[
                  `imageColumn${index + 1}` as keyof ProductFinderProps['productFinderData']
                ] as string)
              : undefined;
          const tooltipColumnHeader =
            `tooltipColumnHeader${index + 1}` in productFinderData
              ? (productFinderData[
                  `tooltipColumnHeader${index + 1}` as keyof ProductFinderProps['productFinderData']
                ] as string)
              : undefined;
          const tooltipColumnContent =
            `tooltipColumnContent${index + 1}` in productFinderData
              ? (productFinderData[
                  `tooltipColumnContent${
                    index + 1
                  }` as keyof ProductFinderProps['productFinderData']
                ] as string)
              : undefined;
          return (
            <li key={`${entry.value}-${index}`} className={styles.listItem}>
              <div data-testid={`product-finder-title-${index}`} className={styles.attribute}>
                <div className={styles.topSideAttribute}>
                  <span data-testid={`product-finder-title-text-${index}`}>{entry.attribute}</span>
                  {tooltipColumnContent && (
                    <InfoToolTip
                      header={tooltipColumnHeader ?? ''}
                      body={tooltipColumnContent}
                      placement="top"
                      classes={{
                        icon: styles.tooltipIcon,
                      }}
                    />
                  )}
                </div>
                <div data-testid={`product-finder-value-${index}`} className={styles.value}>
                  <span>{entry.value}</span>
                </div>
              </div>
              {imageColumn && (
                <NextImage
                  priority={eager}
                  data-testid={`imageColumn${index + 1}`}
                  alt="product finder image"
                  src={imageColumn}
                  height={85}
                  width={85}
                />
              )}
              {entry.subtext && (
                <div data-testid={`product-finder-subtext-${index}`} className={styles.subText}>
                  {entry.subtext}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </ProductRefinementWrapper>
  ) : null;
};

export default ProductFinder;
