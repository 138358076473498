/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { setSelectedNullCartridge } from '../../../actions/shelf/shelf';
import { Button } from '@/components/Button';
import azCommonStyles from '../../../theme/globals.module.scss';
import ProductRefinementWrapper from '../ProductRefinementWrapper';
import styles from './styles.module.scss';
import { useDeleteDefaultVehicleMutation } from '@/features/ymme/api/deleteDefaultVehicle';
import { usePageType } from '@/features/category/api/getPageType';
import { getShopByPageName } from '@/features/category/utils/getShopByPageName';
import { useRouter } from 'next/router';
import { useDispatch } from '@/hooks/redux/useDispatch';

type ProductFilterProps = {
  productFilterData: any;
};

const ProductFilter = (props: ProductFilterProps) => {
  const { backgroundImage, description, header, refinementList, subheader } =
    props.productFilterData || {};
  const dispatch = useDispatch();
  const { mutate: removeDefaultVehicle } = useDeleteDefaultVehicleMutation();
  const router = useRouter();
  const { data: pageTypeData } = usePageType();
  const makeModelYearPageType = getShopByPageName(pageTypeData);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const refinementListLength = refinementList?.length;

  const handleLinkClick = async (url: string | undefined, label: string) => {
    dispatch(setSelectedNullCartridge(label));
    removeDefaultVehicle();

    if (url) {
      router.push(url);
    }
  };

  return refinementListLength && !makeModelYearPageType ? (
    <ProductRefinementWrapper
      backgroundImage={backgroundImage}
      classes={{
        header: styles.header,
        description: styles.description,
        root: styles.root,
      }}
      description={description}
      header={header}
      isTopHeader={isDesktop}
      leftSideProps={{
        lg: 4,
        md: 6,
      }}
      rightSideProps={{
        lg: 8,
        md: 6,
      }}
      containerProps={{
        alignItems: 'flex-start',
      }}
      subheader={subheader}
    >
      <ul className={styles.productFilterLinkList} data-testid="product-filter-link-list">
        {refinementList.map((refinement: any, index: number) => {
          const { label, properties = {} } = refinement;

          if (properties?.seoUrl) {
            return (
              <li key={`${label}-${index}`}>
                <Button
                  variant="link"
                  onClick={() => handleLinkClick(properties.seoUrl, label)}
                  customClass={`${styles.productFilterLink} ${azCommonStyles['az-subtitle-3-regular']}`}
                >
                  {label}
                </Button>
              </li>
            );
          }
        })}
      </ul>
    </ProductRefinementWrapper>
  ) : null;
};

export default ProductFilter;
