/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import Title from '../AZCustomComponent/Title';
import { useLabels } from '@/hooks/useLabels';
import styles from './styles.module.scss';
import { Grid } from '../Grid';

type Props = {
  data: any;
  catLabel: string;
};

const labelMap = {
  searchResultFor: 'label_Search_Results_ResultsForSmall',
  lblResults: 'label_Search_Results_Results',
};

const ShelfHeading = ({ data, catLabel }: Props) => {
  const labels = useLabels(labelMap);

  return data && data.totalNumrecords > 0 ? (
    <div className={styles.shelfHeadingContainer}>
      <Grid container direction={'row'}>
        <Grid item lg={10}>
          <div>
            <Title
              firstText={`${data.totalNumrecords} ${labels.searchResultFor}`}
              secondText={data && catLabel}
              variant="md"
              swapStyle
              stylesClass={styles.textTransformHead}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  ) : null;
};

export default ShelfHeading;
