/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import Head from 'next/head';
import { Ref, Suspense, useEffect, useMemo, useRef, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import root from 'window-or-global';
import useLatestCallback from 'use-latest-callback';
import usePrevious from '@/utils/usePrevious';
import type { HandleFilterType } from '@/types/filterTypes';
import {
  removeDataFromPage,
  removeDataPart,
  setSelectedNullCartridge,
  setProductFilterEvents,
  clearProductFilterEvents,
  setFilterScroll,
  setFilterScrollBrand,
  setSortByOrItemsPerPageFocus,
} from '@/actions/shelf/shelf';
import { countryCodes, defaultStoreUS } from '@/constants/locale';
import { routePaths } from '@/constants/routePaths';
import { shelfPageVariables } from '@/constants/shelfPageVariables';
import { useFilteredSortingOptions } from '@/hooks/useFilteredSortingOptions';
import { useGetCustomPriceFilterLabel } from '@/hooks/useGetCustomPriceFilterLabel';
import { useGetFilterAnalytics } from '@/hooks/useGetFilterAnalytics';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useRouter } from 'next/router';
import azCommonStyles from '@/theme/globals.module.scss';
import type { ReduxState } from '@/types';
import { getBaseSchemaUrl } from '@/utils/common';
import { type CertonaRecommendation, type AddCertonaItemObjectType } from '@/features/certona';
import { useGetCertonaRecommendations } from '@/features/certona/api/useGetCertonaRecommendations';
import { getPathnameFromAsPath, parseUrl } from '@/utils/urlHelpers';
import { FACETED_URL } from '@/utils/validatorRegex';
import { MiniCartModal } from '@/components/Cart/MiniCartModal';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import HeadTemplate from '@/components/PageTemplates/Head';
import { ShelfPageTemplate } from '../PageTemplates/Shelf';
import ShelfSkeleton from '@/components/Skeletons/ShelfSkeleton';
import type { ShelfPageBodyItemGeneric, ShelfResultsBottomItemTwo } from '@/types/legacy';
import { useGetShelfPageRecords } from '../../hooks/useGetShelfPageRecords';
import { isBrandShelfPage } from '@/utils/isBrandShelfPage';
import { useScrollToRef } from '@/hooks/useScrollToRef';
import { eventTypes } from '@/constants/event';
import { getShelfAnalytics, getSelectedSortingOptionLabel } from '@/utils/analytics/shelfAnalytics';
import ShelfTopNavComponent from './ShelfTopNav';
import styles from './styles.module.scss';
import { useAnalytics } from '@/utils/analytics/useAnalytics';
import { usePageViewCall } from '@/utils/analytics/usePageViewCall';
import { usePageSpecificData } from '@/utils/analytics/usePageSpecificData';
import { analyticsDynamicPageName } from '@/utils/analytics/legacyUtils/analyticsDynamicPageName';
import { getContentBlockNames } from '@/utils/analytics/legacyUtils/getContentBlockNames';
import { getContentBlockTypes } from '@/utils/analytics/legacyUtils/getContentBlockTypes';
import { getPageName } from '@/utils/analytics/legacyUtils/getPageName';
import { getProductFinderAnalytics } from '@/utils/analytics/legacyUtils/getProductFinderAnalytics';
import { getAnalyticsRelativeComplementOf } from '@/utils/analytics/getAnalyticsRelativeComplementOf';
import { getSeoPageType } from '@/utils/analytics/legacyUtils/getSeoPageType';
import { pageTypes } from '@/utils/analytics/legacyUtils/pageTypes';
import { setupAnalytics } from '@/utils/analytics/config/setupAnalytics';
import { resetAnalyticsDatalayer } from '@/utils/analytics/dataLayer/resetAnalyticsDatalayer';
import { getProductStrikethroughAnalyticValue } from '@/components/ProductDetail/analytics/getProductStrikethroughAnalyticValue';
import { PreFooter } from '@/features/prefooter';
import { useAppState } from '@/stores/AppState';
import { BazaarVoiceShelf } from '../BazaarVoiceShelf';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useIsStoreSelected } from '@/features/header/hooks/useIsStoreSelected';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { useTopReviews } from '../../api/getTopReviews';
import { useProductList } from '../../context/useProductList';
import { useYmmeDefaultData } from '@/features/ymme/utils/useYmmeDefaultData';
import { useProductShelfResults } from '@/features/category/api/getProductShelfResults';
import { useQueryClient } from '@tanstack/react-query';
import { getProductSkuDetailsKey } from '@/features/product/api/getProductSkuDetails';
import { useDeviceType } from '@/utils/useDeviceType';
import { DynamicLinks, VehicleDynamicLinks } from '@/features/dlm';
import { FeatureFlag, useFeatureFlag } from '@/features/globalConfig';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { useInteractionCheck } from '@/hooks/useInteractionCheck';
import { sessionStorage } from '@/utils/sessionStorage';
import { MiniCartFlyoutComp } from '@/features/miniCartFlyout';
import { Drawer } from '@/components/Drawer/Drawer';
import { selectOrderDetailsFromState } from '@/constants/selectors';
import Hidden from '@/components/Hidden';
import { siteSections } from '@/utils/siteSections';
import { useBrandProductData } from '@/features/brands/api/getProductBrandData';
import { useDealDrawer } from '@/features/deals/hooks/useDealDrawer';
import { TrapFocus } from '@/components/Dialog/Modal/TrapFocus';
import { useProductListView } from '../../context/useProductListView';
import { deleteFromHistoryStateOptions } from '@/features/common/utils/deleteFromHistoryStateOptions';
import { useSeoMetadata } from '@/features/common/api/getSeoMetadata';
import { type SeoMetadataInfo } from '@/features/common';
import { getCartridgeType } from '@/utils/getCartridgeType';
import { FilterMobileDrawer } from '@/components/Filter/FilterMobileDrawer';
import dynamic from 'next/dynamic';
import { useSearchProductData } from '@/features/search/api/getSearchProductData';
import { useAutoCloseOnOrderReady } from '@/features/sisterStoreFlyout';
import { useIs24ProductViewEnabled } from '@/hooks/useIs24ProductViewEnabled';
import { CMSShelfResultHeaderModularHandler, CMSShelfLeftNav } from './CMSModularHandler';
import { CMSResultBottom } from './CMSModularHandler/CMSResultBottom';
import { usePageType as usePageTypeLegacy } from '@/hooks/usePageType';
import { pageTypes as pageTypeConstants } from '@/constants/page';
import { historyStateOptionAttributes } from '@/constants/historyStateOption';
import { formatPartTypeId } from '@/utils/formatPartTypeId';
import { View } from '@az/starc-ui';
import { CMSSeoTags } from '@/features/contentstack/components/CMSSeoTags/CMSSeoTags';
import ProgressModal from '@/components/AZCustomComponent/ProgressModal';
import { useContentStackPLPData } from '@/features/productListingPage/api/getContentStackProductListingPageData';
import { CMSPLPBodyModularHandler } from '@/features/productListingPage/components/CMSModularHandler'; // eslint-disable-line no-restricted-imports
import { usePageType } from '@/features/category/api/getPageType';
import { useMonetateDecisionFlag } from '@/features/kibo';
import { getShelfPageType } from '@/utils/getShelfpagetType';
import { useGetSessionFiltersList } from '@/hooks/useGetSessionFiltersList';

const LazyYearsTable = dynamic(
  () => import('../YearsTable/YearsTable').then(({ YearsTable }) => ({ default: YearsTable })),
  { ssr: true }
);

const LazyMiniCartFlyoutComp = dynamic(
  () => import('@/features/miniCartFlyout').then((mod) => mod.MiniCartFlyoutComp),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

const { siteSectionProductShelf } = siteSections;
const labelMap = {
  about: 'label_generic_about',
  featuredSortingOption: 'label.sort.featured',
};

type ShelfProductSchema = {
  '@type': string;
  name: string;
  image: string;
  url: string;
  sku: number;
  description: string;
  mpn: string;
  brand: { '@type': 'Brand'; name: string };
  offers?: {
    '@type': 'Offer';
    availability: 'https://schema.org/InStock';
    price: string;
    priceCurrency: string;
  };
};

type Props = {
  shelfRef?: Ref<HTMLDivElement>;
  mainShelfRef?: Ref<HTMLDivElement>;
};

export const ProductShelf = ({ shelfRef, mainShelfRef }: Props) => {
  const isUtagDefined = useAnalytics();
  const pageViewCall = usePageViewCall();
  const router = useRouter();
  const { asPath } = router;
  // TO DO add minicartSuccessNote to global data
  const cartSuccessData = useSelector(({ shelf }: ReduxState) => shelf.cartSuccess);
  const selectedFilterValue = useSelector(({ shelf }: ReduxState) => shelf.selectedFilterValue);
  const eventType = useSelector(({ shelf }: ReduxState) => shelf.eventType);
  const readyStatus = useSelector(({ shelf }: ReduxState) => shelf.readyStatus);
  const isRefresh = useSelector(({ shelf }: ReduxState) => shelf.isRefresh);
  const selectedNullCartridge = useSelector(({ shelf }: ReduxState) => shelf.selectedNullCartridge);
  const isList = useSelector(({ shelf }: ReduxState) => shelf.isList);
  const queryClient = useQueryClient();
  const {
    data: plpCMSData,
    isCSProductListingPageEnabled,
    isFetching: isPLPCMSDataFetching,
    isSuccess: isPLPCMSDataSuccess,
    isError: isPLPCMSDataError,
  } = useContentStackPLPData({ enabled: true });

  const yearsTableEnabled = useFeatureFlag('YEARS_TABLE_ENABLED') === 'true';
  const isShelfCertonaAddToCartEnabled =
    useFeatureFlag('IS_SHELF_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const refQuickFilter = useRef<HTMLElement>(null);
  const ymmeRef = useRef<HTMLDivElement>(null);
  const miniCartModalRef = useRef<HTMLDivElement>(null);
  const lazyMiniCartModalRef = useRef<HTMLDivElement>(null);
  const previousReadyStatus = usePrevious(readyStatus);
  const { data: headerData } = useHeaderData();
  const preferredVehicle = usePreferredVehicle();
  const dispatch = useDispatch();
  const miniCartMap = headerData?.miniCartMap;
  const minicart = Boolean(headerData?.miniCartSuccessNote);
  const {
    state: { campaignModalEnabled },
  } = useAppState();
  const previousCampaignModalEnabled = usePrevious(campaignModalEnabled);
  const { filterScroll } = useSelector(({ shelf }: ReduxState) => shelf);
  const orderDetailsFromState = useSelector(selectOrderDetailsFromState);
  const { setPageSpecificData } = usePageSpecificData();

  const removeData = () => dispatch(removeDataPart());
  const dispatchRemoveDataFromPage = () => dispatch(removeDataFromPage());
  const dispatchClearProductFilterEvents = () => dispatch(clearProductFilterEvents());
  const dispatchSetFilterScroll = (value: boolean) => dispatch(setFilterScroll(value));
  const dispatchSetSelectedNullCartridge = (value: string) =>
    dispatch(setSelectedNullCartridge(value));
  const dispatchSetProductFilterEvents = (
    filterName: string,
    filterId: string,
    checked: boolean,
    quickFilter?: boolean
  ) => dispatch(setProductFilterEvents(filterName, filterId, checked, quickFilter));
  const dealDrawerShelfEnabled = useFeatureFlag('DEAL_DRAWER_SHELF_ENABLED') === 'true';
  const strikeThroughPricingEnabled = useFeatureFlag('SHOW_STRIKE_THROUGH_PRICING') === 'true';
  const storeSelected = useIsStoreSelected();
  const dealBadgeEnabled = dealDrawerShelfEnabled && storeSelected;
  const { data: productShelfResultsData, isFetching: productShelfResultsIsFetching } =
    useProductShelfResults();

  // what if we passed this data from useBrand or useCategory? Routing component always uses useCategory and BrandShelf uses useBrand
  const {
    data: shelfData,
    isSuccess: shelfRequestSuccess,
    isFetching: shelfRequestFetching,
    productSkuIsFetching,
    secondaryProductSkuIsFetching,
    setShouldFetchSecondaryProductSkus,
  } = useProductList();

  const [hasFetchedSecondarySkus, setHasFetchedSecondarySkus] = useState(false);
  const isBrandShelf = usePageTypeLegacy().pageType === pageTypeConstants.BrandShelf;
  const enable24ProductView = useIs24ProductViewEnabled();
  const { data: searchProductData } = useSearchProductData({ enabled: false });

  const { data: brandShelfData, isFetching: isBrandShelfDataFetching } = useBrandProductData({
    enabled: isBrandShelfPage(router.asPath),
  });

  const makeOrModelName = shelfData?.makeorModelName;
  const { data: ymmeData } = useYmmeDefaultData();
  const defaultEngine = ymmeData.defaultEngine;
  const { data: pageTypeData } = usePageType();

  const shopByUrlMake = pageTypeData?.make ?? undefined;
  const shopByUrlModel = pageTypeData?.model ?? undefined;
  const shopByUrlYear = pageTypeData?.year ?? undefined;

  const { data: seoMetadataInfo } = useSeoMetadata({
    enabled: true,
    vehicleMake: shopByUrlMake,
    vehicleModel: shopByUrlModel,
    vehicleYear: shopByUrlYear,
  });

  const isMultiSelectURL = useMemo(() => {
    return /\/b\/.*~/.test(router.asPath);
  }, [router.asPath]);

  useScrollToRef({
    ref: refQuickFilter,
    dependencies: [readyStatus, filterScroll],
    conditions: readyStatus !== previousReadyStatus && shelfRequestSuccess && !!filterScroll,
    afterScroll: () => dispatchSetFilterScroll(false),
  });

  useScrollToRef({
    ref: ymmeRef,
    dependencies: [campaignModalEnabled],
    conditions: !!previousCampaignModalEnabled && !campaignModalEnabled,
    scrollOffset: 60,
  });

  const [showMiniCartModal, setShowMiniCartModal] = useState(false);
  const [certonaRecommendationAddedToCart, setCertonaRecommendationAddedToCart] = useState(false);
  const [addedCertonaItemObject, setAddedCertonaItemObject] =
    useState<AddCertonaItemObjectType | null>(null);
  const [showMiniCartFlyoutModal, setShowMiniCartFlyoutModal] = useState(false);
  const getCustomPriceFilterLabel = useGetCustomPriceFilterLabel();
  const getFilterAnalytics = useGetFilterAnalytics();
  const sessionFiltersList = useGetSessionFiltersList();
  const scrolled = useRef(false);
  const deviceType = useDeviceType();
  const isFetchingLocation = useSelector(({ appData }: ReduxState) => appData.isFetchingLocation);
  const searchRedirectText =
    typeof router.query.searchText === 'string' ? router.query.searchText : '';

  const isBrandPremiumPage = shelfData?.isBrandPremiumPage;
  const productFinderData =
    shelfData?.productFinderData ??
    productShelfResultsData?.productShelfResults?.vehicleProductAttributes;
  const seoTopCopy = seoMetadataInfo?.topContentSeoActive ? seoMetadataInfo.copyBlockOne : null;

  const makeModelYearPageType = shelfData?.makeModelYearPageType
    ? shelfData.makeModelYearPageType
    : shopByUrlMake
    ? shopByUrlModel
      ? shopByUrlYear
        ? 'ShopByYear'
        : 'ShopByModel'
      : 'ShopByMake'
    : null;

  const shelfBreadcrumb = shelfData?.shelfBreadcrumb;
  const shelfHead = shelfData?.shelfHead;
  const shelfLeftNav = shelfData?.shelfLeftNav;
  const shelfPageBody = shelfData?.shelfPageBody;
  const shelfResultHeader = shelfData?.shelfResultHeader;
  const shelfResultBottom = shelfData?.shelfResultBottom;
  const shelfTippyTop = shelfData?.shelfTippyTop;
  const shortDescription = seoTopCopy;

  const isVisualFiltersEnabled = useMonetateDecisionFlag('visualPartTypes');
  const isNavigatedFromVisualFilter = Boolean(
    isVisualFiltersEnabled &&
      router.query.searchText &&
      !searchProductData?.redirectLocation &&
      !router.asPath.includes('redirectUrl') &&
      searchProductData?.redirectLocation !== router.asPath.split('&searchText')[0]
  );

  const parts = shelfData?.shelfParts ?? [];
  const isABot = deviceType === 'bot';
  const [topReviewsQueryEnabled, setTopReviewsQueryEnabled] = useState(false);
  const productId = parts[0]?.skuPricingAndAvailability?.productId;
  const shelfProductId = shelfData?.productId;
  const gcpUrlEnabled = useFeatureFlag('SEO_GCP_URL_ENABLED') === 'true';
  const { data: reviews } = useTopReviews({
    enabled: topReviewsQueryEnabled,
    partTypeId: productId,
    pageType: makeModelYearPageType,
    vehicleMake: shopByUrlMake,
    vehicleModel: shopByUrlModel,
    vehicleYear: shopByUrlYear,
    minimumReviewRating: undefined,
    gcpUrlEnabled,
  });
  const {
    pfmLocation,
    searchOriginalPartTypeId,
    searchProductRepositoryId,
    searchResultPosition,
    productFindingMethod,
    ignoreNextPageViewCall,
    eventType: eventTypeAnalytics,
  } = usePageSpecificData().pageSpecificData;
  const [analyticsCallSecondarySkus, setAnalyticsCallSecondarySkus] = useState(false);
  const labels = useLabels(labelMap);
  const [sortingOptions = []] = useFilteredSortingOptions() || [];
  const showSortOptions = !!sortingOptions.length;
  const { query } = parseUrl(asPath);
  const locale = useLocale();
  const [showCertonaMiniCartFlyoutModal, setShowCertonaMiniCartFlyoutModal] = useState(false);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const desktopMiniCartFlyoutEnabled =
    useFeatureFlag('IS_DESKTOP_MINI_CART_FLYOUT_ENABLED') === 'true';
  const mobileMiniCartFlyoutEnabled =
    useFeatureFlag('IS_MOBILE_MINI_CART_FLYOUT_ENABLED') === 'true';
  const tabletMiniCartFlyoutEnabled =
    useFeatureFlag('IS_TABLET_MINI_CART_FLYOUT_ENABLED') === 'true';
  const isNewDealsApiEnabled = useFeatureFlag('IS_NEW_DEALS_API_ENABLED') === 'true';
  const isMiniCartFlyoutEnabled =
    (isDesktop && desktopMiniCartFlyoutEnabled) ||
    (isMobile && mobileMiniCartFlyoutEnabled) ||
    (isTablet && tabletMiniCartFlyoutEnabled);
  const selectedsortValue = getSelectedSortingOptionLabel(
    query.sort,
    sortingOptions,
    labels.featuredSortingOption
  );
  const skuView = parts.map(() => '1');
  const pageRecData = useGetShelfPageRecords(shelfPageBody);
  const parsedPath = getPathnameFromAsPath(router.asPath);
  const totalRecs =
    searchRedirectText && isBrandShelfPage(parsedPath)
      ? brandShelfData?.records?.length
      : pageRecData?.totalNumRecs ?? 0;
  const userInteracted = useInteractionCheck();
  const [miniCartFlyoutVisible, setMiniCartFlyoutVisible] = useState(false);
  const { data: storeDetails } = useStoreDetailsData();
  const { isMobileFilterOpen } = useProductListView();
  const toggleMiniCartFlyout = () => {
    setMiniCartFlyoutVisible(() => !miniCartFlyoutVisible);
  };
  const isMerchShelfPage = router.route === '/deals/[cmsPath]/[[...slug]]';

  const showSkeleton =
    !isRefresh && (isFetchingLocation || shelfRequestFetching || !shelfRequestSuccess);

  const shelfCatLabel = showSkeleton
    ? undefined
    : seoMetadataInfo?.pageHeadingName ??
      plpCMSData?.additional_attributes_merch_shelf_page?.heading;

  const productStrikeThroughPricing: string[] = [];
  const analyticsParts = enable24ProductView
    ? analyticsCallSecondarySkus
      ? parts.slice(12)
      : parts.slice(0, 12)
    : parts;

  analyticsParts.forEach((part) => {
    const strikethroughProduct = getProductStrikethroughAnalyticValue(
      part.skuPricingAndAvailability,
      part.skuPricingAndAvailability?.discountedPrice,
      strikeThroughPricingEnabled
    );
    productStrikeThroughPricing.push(strikethroughProduct[0]);
  });

  const partTypeFilterAnalytic = isBrandShelf
    ? brandShelfData?.staticNavigation
        ?.find((filter) => filter.dimensionName === 'part_type_name_s')
        ?.refinements.map((refinement) => `${refinement.label} (${refinement.count})`)
        .join(', ')
    : `${productShelfResultsData?.productShelfResults?.pageDisplayName} (${productShelfResultsData?.productShelfResults?.totalNumberOfRecords})`;
  useEffect(() => {
    return () => {
      setPageSpecificData({
        productFindingMethod: '',
        pfmLocation: '',
        eventType: '',
      });
      dispatchRemoveDataFromPage();
      resetAnalyticsDatalayer(shelfPageVariables);
      dispatch(setSortByOrItemsPerPageFocus(false, false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [analyticsCalled, setAnalyticsCalled] = useState(false);
  const prevSecondaryProductSkuIsFetching = usePrevious(secondaryProductSkuIsFetching);
  useEffect(() => {
    if (prevSecondaryProductSkuIsFetching && !secondaryProductSkuIsFetching && analyticsCalled) {
      setAnalyticsCalled(false);
      setAnalyticsCallSecondarySkus(true);
    }
  }, [
    secondaryProductSkuIsFetching,
    prevSecondaryProductSkuIsFetching,
    setAnalyticsCalled,
    analyticsCalled,
  ]);
  const prevVehicleId = usePrevious(preferredVehicle?.catalogVehicleId);
  const prevAsPath = usePrevious(asPath);
  const prevStoreNumber = usePrevious(storeDetails?.storeNumber);

  const toggleCertonaMiniCartFlyout = () => {
    setShowCertonaMiniCartFlyoutModal(() => !showCertonaMiniCartFlyoutModal);
    closeStoreModal();
  };

  useEffect(() => {
    if (
      prevVehicleId !== preferredVehicle?.catalogVehicleId ||
      prevAsPath !== asPath ||
      storeDetails?.storeNumber !== prevStoreNumber
    ) {
      setAnalyticsCalled(false);
      setAnalyticsCallSecondarySkus(false);
    }
  }, [
    asPath,
    preferredVehicle?.catalogVehicleId,
    prevAsPath,
    prevStoreNumber,
    prevVehicleId,
    storeDetails?.storeNumber,
  ]);

  useEffect(() => {
    if (!!addedCertonaItemObject) {
      setShowCertonaMiniCartFlyoutModal(true);
    }
  }, [addedCertonaItemObject]);

  useEffect(() => {
    const parsedPath = getPathnameFromAsPath(router.asPath);
    const isBrandsPage = isBrandShelfPage(parsedPath);

    if (
      isUtagDefined &&
      shelfRequestSuccess &&
      !productSkuIsFetching &&
      !secondaryProductSkuIsFetching &&
      !analyticsCalled &&
      !shelfRequestFetching &&
      !isBrandShelfDataFetching &&
      !ignoreNextPageViewCall &&
      userInteracted
    ) {
      const pageName = isBrandsPage
        ? typeof router.query.brandName === 'string'
          ? router.query.brandName
          : router.query.brandName?.join()
        : analyticsDynamicPageName(shelfBreadcrumb, 'shelf');
      const leafCategoryName = shelfBreadcrumb?.slice(-1)[0]?.label;
      const pfmLocationAnalytics =
        pfmLocation &&
        eventTypeAnalytics !== eventTypes.productFitmentBanner &&
        eventTypeAnalytics !== eventTypes.productToastNotification
          ? `${pfmLocation}: ${leafCategoryName ?? ''}`
          : pfmLocation
          ? pfmLocation
          : '';
      const productFinderAnalytics = getProductFinderAnalytics(productFinderData);
      const segmentArrayCount = asPath.split('/').length;
      const hasTrailingSlash = asPath.endsWith('/');
      const segmentCount = segmentArrayCount - 1 - (hasTrailingSlash ? 1 : 0);
      let analyticsPageType:
        | 'catalog_shelf'
        | 'faceted_nav'
        | 'make_part_type'
        | 'Brand Profile'
        | 'Brand Profile Premium'
        | 'model_part_type'
        | 'year_make_model_part_type' = pageTypes.productShelf;
      const isFacetedUrl = FACETED_URL.test(parsedPath);
      if (isFacetedUrl) {
        analyticsPageType = pageTypes.facetedNav;
      } else if (isBrandsPage) {
        analyticsPageType = isBrandPremiumPage ? pageTypes.brandShelfPremium : pageTypes.brandShelf;
      } else {
        switch (segmentCount) {
          case 3:
            analyticsPageType = pageTypes.makePartType;
            break;

          case 4:
            analyticsPageType = pageTypes.modelPartType;
            break;

          case 5:
            analyticsPageType = pageTypes.yearMakeModelPartType;
            break;

          default:
            break;
        }
      }

      if (root.location.href !== root.utag_data?.pageURL) {
        //if coming in from category page, useAnalytics gets called before the url changes since shelf and category share the same general route
        //we need to re-setup analytics so it has the correct pageURL in this scenario
        setupAnalytics(false, router.asPath, isUtagDefined);
      }

      const removeUnusedAnalyticsData = ['rewardPoints'];
      let pageType: 'catalog_shelf' | 'Brand Profile Premium' | 'Brand Profile' =
        pageTypes.productShelf;

      if (isBrandsPage) {
        pageType = isBrandPremiumPage ? pageTypes.brandShelfPremium : pageTypes.brandShelf;
      }

      const hasFromStringSearchParam = query.fromString && query.fromString === 'search';
      const visualCategoryRank = sessionStorage.getItem('visualCategoryRank');
      const productFindingMethodFromUtag =
        typeof root.utag_data?.productFindingMethod === 'string'
          ? root.utag_data.productFindingMethod
          : '';
      const utagData = {
        pageName: getPageName(pageType, pageName ?? ''),
        siteSection: siteSectionProductShelf,
        pageType: isBrandsPage ? pageTypes.brandShelf : pageTypes.productShelf,
        pageHierarchy: `Home|${
          pageName?.includes('-')
            ? pageName
                .replace(/-/g, ' ')
                .replace(/:/g, '|')
                .replace(/\b([a-z])/g, (x) => x.toUpperCase())
            : pageName?.replace(/\b([a-z])/g, (x) => x.toUpperCase()) ?? ''
        }`,
        searchType:
          searchProductData?.isUnitOfMeasureMatch ||
          productShelfResultsData?.productShelfResults?.isUnitOfMeasureMatch
            ? 'UnitOfMeasure'
            : undefined,
        contentBlockNames: [
          ...getContentBlockNames(shelfTippyTop),
          ...getContentBlockNames(shelfResultHeader),
          ...getContentBlockNames(shelfResultBottom),
          ...getContentBlockNames(shelfPageBody),
          ...getContentBlockNames(shelfLeftNav),
          ...getContentBlockNames(shelfHead),
        ],
        contentBlockTypes: [
          ...getContentBlockTypes(shelfTippyTop),
          ...getContentBlockTypes(shelfResultHeader),
          ...getContentBlockTypes(shelfResultBottom),
          ...getContentBlockTypes(shelfPageBody),
          ...getContentBlockTypes(shelfLeftNav),
          ...getContentBlockTypes(shelfHead),
        ],
        seoPageType: getSeoPageType(analyticsPageType, router.asPath),
        seoPopularSearches: !!document.getElementById('#popular-searches-container'),
        seoPopularSearchesClick: sessionStorage.getItem('popularSearchClicked') === 'true',
        selectedsortValue: showSortOptions ? selectedsortValue : null,
        ...productFinderAnalytics,
        searchOriginalPartTypeId,
        searchProductRepositoryId,
        searchResultPosition,
        ...getShelfAnalytics({
          isList,
          parts: analyticsParts,
          shelfBreadcrumb,
          shelfPageBody,
          brandPageBody: brandShelfData,
          shelfCatLabel,
          shelfLeftNav,
          selectedNullCartridge,
          headerData,
          query,
          getCustomPriceFilterLabel,
          getFilterAnalytics,
          sessionFiltersList,
          makeOrModelName,
          defaultEngine,
          dealBadgeEnabled,
        }),
        eventType:
          eventType ||
          (searchRedirectText && !!totalRecs
            ? 'successfulSearch'
            : searchRedirectText && totalRecs === 0
            ? 'nullSearch'
            : undefined),
        selectedFilterValue: selectedFilterValue,
        ...(searchRedirectText
          ? {
              searchKeyword: searchRedirectText,
            }
          : hasFromStringSearchParam && !!query.filterByKeyWord
          ? {
              searchKeyword: query.filterByKeyWord,
            }
          : {}),
        searchMethod: sessionStorage.getItem('searchMethod') || '',
        prodView: eventTypes.prodView,
        skuView: skuView,
        productFindingMethod: isNavigatedFromVisualFilter
          ? 'Visual Part Type Search'
          : productFindingMethod || productFindingMethodFromUtag,
        productFitmentBanner:
          eventTypeAnalytics === eventTypes.productFitmentBanner ? eventTypeAnalytics : '',
        productToastNotification:
          eventTypeAnalytics === eventTypes.productToastNotification ? eventTypeAnalytics : '',
        pfmLocation:
          isNavigatedFromVisualFilter && typeof visualCategoryRank === 'string'
            ? `Visual Category Rank ${visualCategoryRank}`
            : pfmLocationAnalytics,
        productStrikeThroughPricing: productStrikeThroughPricing,
        pageLazyLoad: enable24ProductView && analyticsCallSecondarySkus,
        partTypeFiltersAvailable: partTypeFilterAnalytic,
      };
      pageViewCall(utagData, [
        ...removeUnusedAnalyticsData,
        ...getAnalyticsRelativeComplementOf(utagData, undefined),
      ]);
      dispatchClearProductFilterEvents();
      setAnalyticsCalled(true);
      sessionStorage.removeItem('searchMethod');
      sessionStorage.setItem('performedSearch', 'false');
      //clear search variables after pageViewCall
      setPageSpecificData({
        searchOriginalPartTypeId: '',
        searchProductRepositoryId: '',
        searchResultPosition: '',
      });
      sessionStorage.setItem('popularSearchClicked', 'false');
      selectedNullCartridge && dispatchSetSelectedNullCartridge('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUtagDefined,
    shelfRequestSuccess,
    shelfRequestFetching,
    isBrandShelfDataFetching,
    productSkuIsFetching,
    secondaryProductSkuIsFetching,
    asPath,
    preferredVehicle?.catalogVehicleId,
    userInteracted,
    analyticsCalled,
  ]);

  useAutoCloseOnOrderReady();

  useEffect(() => {
    if (prevSecondaryProductSkuIsFetching && !secondaryProductSkuIsFetching) {
      setHasFetchedSecondarySkus(true);
    }
  }, [prevSecondaryProductSkuIsFetching, secondaryProductSkuIsFetching]);

  useEffect(() => {
    if (
      root.history.state &&
      'options' in root.history.state &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      'latestSelectedPageYOffset' in root.history.state.options
    ) {
      if (enable24ProductView && setShouldFetchSecondaryProductSkus) {
        setShouldFetchSecondaryProductSkus(true);
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const latestSelectedPageYOffset = root.history.state.options
        .latestSelectedPageYOffset as string;
      if (
        parseInt(latestSelectedPageYOffset) > 0 &&
        shelfRequestSuccess &&
        !isBrandShelf &&
        !productSkuIsFetching &&
        (!isCSProductListingPageEnabled || isPLPCMSDataSuccess) &&
        (!enable24ProductView || hasFetchedSecondarySkus)
      ) {
        root.scroll({
          top: parseInt(latestSelectedPageYOffset),
        });
        deleteFromHistoryStateOptions(historyStateOptionAttributes.latestSelectedPageYOffset);
      } else if (
        parseInt(latestSelectedPageYOffset) > 0 &&
        shelfRequestSuccess &&
        isBrandShelf &&
        !productSkuIsFetching &&
        (!enable24ProductView || hasFetchedSecondarySkus)
      ) {
        root.scroll({
          top: parseInt(latestSelectedPageYOffset),
        });
        deleteFromHistoryStateOptions(historyStateOptionAttributes.latestSelectedPageYOffset);
      }
    }
  }, [
    isBrandShelf,
    shelfRequestSuccess,
    productSkuIsFetching,
    hasFetchedSecondarySkus,
    enable24ProductView,
    setShouldFetchSecondaryProductSkus,
    isPLPCMSDataSuccess,
    isCSProductListingPageEnabled,
  ]);

  useEffect(() => {
    if (cartSuccessData && minicart) {
      setShowMiniCartModal(true);
      setMiniCartFlyoutVisible(true);
      setShowMiniCartFlyoutModal(true);
    }
  }, [cartSuccessData, minicart]);

  useEffect(() => {
    return () => {
      removeData();
      setMiniCartFlyoutVisible(false);
      setShowMiniCartFlyoutModal(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchReviewsOnScroll = () => {
      if (!scrolled.current && productId && !isABot) {
        scrolled.current = true;
        setTopReviewsQueryEnabled(true);
      } else {
        root.removeEventListener('scroll', fetchReviewsOnScroll);
      }
    };
    if (
      (makeModelYearPageType === 'ShopByModel' || makeModelYearPageType === 'ShopByYear') &&
      locale !== countryCodes.mx
    ) {
      root.addEventListener('scroll', fetchReviewsOnScroll);
    }

    return () => {
      scrolled.current = false;
      root.removeEventListener('scroll', fetchReviewsOnScroll);
    };
  }, [
    asPath,
    shopByUrlMake,
    shopByUrlModel,
    shopByUrlYear,
    makeModelYearPageType,
    locale,
    productId,
    isABot,
  ]);

  useEffect(() => {
    if (isPLPCMSDataError) {
      void router.replace('/NotFound');
    }
  }, [isPLPCMSDataError, router]);

  const vehiclesInfo = headerData?.vehicleMap;
  const vehicleId = vehiclesInfo?.catalogVehicleId;
  const pageType = getShelfPageType(productShelfResultsData?.productShelfResults?.taxonomyId);
  const shelfSkuRecords = productShelfResultsData?.productShelfResults?.skuRecords;
  const skuIds = shelfSkuRecords
    ?.slice(0, 10)
    .map((skuRecord) => skuRecord.itemId)
    .join(';');

  const certonaRecommendations = useGetCertonaRecommendations(
    {
      certonaType: pageType!,
      vehicleID: vehicleId,
      // sending the 1st product's partType as all the shelf product's has the same part type
      parttype: formatPartTypeId(shelfSkuRecords?.[0]?.originalPartTypeId),
      skuIds,
    },
    [shelfRequestSuccess, productShelfResultsData],
    shelfRequestSuccess && Boolean(productShelfResultsData)
  );

  const closeStoreModal = () => {
    removeData();
    setShowMiniCartModal(false);
    setMiniCartFlyoutVisible(false);
    setShowMiniCartFlyoutModal(false);
  };

  const submitToCart = () => {
    void router.push(routePaths.cart);
  };

  const renderMiniCart = () => {
    const addToCartItem =
      isABot && parts
        ? parts[0]
        : parts.find((item) => String(item.skuNumber) === cartSuccessData?.skuId);
    const productImage = addToCartItem?.itemImageUrl || addToCartItem?.productImageUrl || '';
    const productName = {
      productBrand: addToCartItem?.brand || '',
      productPart: addToCartItem?.name || '',
      quickNote: addToCartItem?.quickNote || '',
    };
    const productAvailabilityInfo = {
      skuPricingAndAvailability: addToCartItem?.skuPricingAndAvailability,
      dealsInfo: addToCartItem?.dealsInfo,
      aZBadgesFlagVO: addToCartItem?.aZBadgesFlagVO,
      availabilityInfoVO: addToCartItem?.availabilityInfoVO,
    };
    const productDetails = {
      productName,
      productAvailabilityInfo,
      productPartType: shelfSkuRecords?.[0]?.originalPartTypeId,
    };
    const checkToOpenMiniCartModal =
      addToCartItem &&
      (cartSuccessData || minicart) &&
      (showMiniCartModal || showMiniCartFlyoutModal);

    const renderOldMiniCart = () => {
      return (
        <Hidden only={['sm', 'md']}>
          <MiniCartModal
            // @ts-expect-error fix type
            isModalOpen={global !== 'undefined' ? showMiniCartModal : false}
            image={productImage}
            successData={cartSuccessData}
            productDetails={productDetails}
            closeStoreModal={closeStoreModal}
            cartUrl={submitToCart}
            productTitle={productName.productPart}
            pageName="shelf"
          />
        </Hidden>
      );
    };

    const renderNewFlyoutMiniCart = () => {
      return (
        <Drawer
          data-testid="deals-bundle-drawer-sister-store-flyout"
          anchor={'right'}
          open={miniCartFlyoutVisible}
          onClose={toggleMiniCartFlyout}
          PaperProps={{ className: styles.customPaperDrawer }}
          drawerRef={miniCartModalRef}
        >
          <TrapFocus open={miniCartFlyoutVisible}>
            <div className={styles.miniCartFlyoutDialog} role="dialog" aria-modal="true">
              <MiniCartFlyoutComp
                handleClose={toggleMiniCartFlyout}
                isModalOpen={showMiniCartFlyoutModal}
                image={productImage}
                successData={cartSuccessData}
                productDetails={productDetails}
                miniCartData={miniCartMap}
                closeStoreModal={closeStoreModal}
                cartUrl={submitToCart}
                productTitle={productName.productPart}
                pageName="pdp"
                orderDetailsFromState={orderDetailsFromState}
                parentRef={miniCartModalRef}
              />
            </div>
          </TrapFocus>
        </Drawer>
      );
    };

    if (checkToOpenMiniCartModal) {
      if (isMiniCartFlyoutEnabled) {
        return renderNewFlyoutMiniCart();
      } else if (!isMobile) {
        return renderOldMiniCart();
      }
    }
  };

  const getWebPageData = () => {
    const baseUrl = getBaseSchemaUrl(locale);

    const webPageData = {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      name: shelfCatLabel,
      url: `${baseUrl}${router.asPath}`,
      mainEntity: {
        '@type': 'WebPageElement',
        offers: {
          '@type': 'Offer',
          itemOffered: parts.map((part) => {
            const {
              name,
              productImageUrl: image,
              seoUrl,
              brand,
              skuNumber: sku,
              partNumber,
              description,
            } = part;
            const productSchema: ShelfProductSchema = {
              '@type': 'Product',
              name,
              image,
              description,
              mpn: partNumber,
              url: `${baseUrl}${seoUrl}`,
              brand: { '@type': 'Brand', name: brand },
              sku,
            };
            const retailPrice = part.skuPricingAndAvailability?.retailPrice || 0;
            const corePrice = part.skuPricingAndAvailability?.corePrice || 0;
            const price = (retailPrice + corePrice).toFixed(2);

            if (price !== '0.00') {
              productSchema.offers = {
                '@type': 'Offer',
                availability: 'https://schema.org/InStock',
                price,
                priceCurrency: locale === countryCodes.mx ? 'MXN' : 'USD',
              };
            }

            return productSchema;
          }),
        },
      },
    };
    return JSON.stringify(webPageData);
  };

  const renderShelfHelmetV2 = (seoMetaData: SeoMetadataInfo) => {
    if (
      Object.values(seoMetaData).every((x) => x === null || x === '' || x === true) &&
      !parts[0]
    ) {
      return null;
    }

    const renderHelmet = (
      <>
        <Head>
          <title key="title">{seoMetaData.pageTitle}</title>
          {seoMetaData.canonicalUrl && (
            <>
              <link key="linkCanonical" rel="canonical" href={`${seoMetaData.canonicalUrl}`} />
              <link
                key="linkAlternateLocale"
                rel="alternate"
                hrefLang={locale.toLowerCase()}
                href={seoMetaData.canonicalUrl}
              />
              {seoMetaData.alternateUrl && (
                <link
                  key="linkAlternateObj"
                  rel="alternate"
                  hrefLang={`${seoMetaData.langaugeLocale}`}
                  href={`${seoMetaData.alternateUrl}`}
                />
              )}
            </>
          )}
          {seoMetaData.indexEnabledFlag ? (
            <>
              {seoMetaData.pageContentDescription && (
                <meta
                  key="metaDescription"
                  name="description"
                  content={seoMetaData.pageContentDescription}
                />
              )}
              {seoMetaData.pageTitle && (
                <meta key="metaTitle" name="og:title" content={seoMetaData.pageTitle} />
              )}
              {seoMetaData.currentPageUrl && (
                <meta key="metaUrl" property="og:url" content={seoMetaData.currentPageUrl} />
              )}
              {parts[0]?.productImageUrl && (
                <meta key="metaImage" property="og:image" content={parts[0]?.productImageUrl} />
              )}
              {seoMetaData.siteName && (
                <meta key="metaSiteName" property="og:site_name" content={seoMetaData.siteName} />
              )}
              <meta key="metaType" property="og:type" content="shelf" />
            </>
          ) : (
            <>
              <meta
                key="metaRobots"
                name="robots"
                content={isMultiSelectURL ? 'noindex, nofollow' : 'noindex, follow'}
              />
              {seoMetaData.pageTitle && (
                <meta key="metaTitle" name="title" content={seoMetaData.pageTitle} />
              )}
            </>
          )}

          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: getWebPageData(),
            }}
          />
        </Head>
      </>
    );

    return <Fragment>{renderHelmet}</Fragment>;
  };

  const handleFilter = useLatestCallback((filter: HandleFilterType) => {
    const { checked, displayName, quickFilter, refinement, selectionType } = filter;
    const { label, properties } = refinement;
    const isQuickFilter = !!quickFilter && !isDesktop;
    const filterName = `${selectionType}: ${displayName}: ${label}`;
    const filterId = properties.filters;

    dispatch(setFilterScrollBrand(true));

    dispatchSetProductFilterEvents(filterName, filterId, checked, isQuickFilter);
  });
  const { renderDealDrawer, handleDealClick } = useDealDrawer();
  const catalogVehicleId = preferredVehicle?.catalogVehicleId ?? '';
  const storeNumber =
    locale === countryCodes.us
      ? storeDetails?.storeNumber || defaultStoreUS
      : storeDetails?.storeNumber;
  const yearsRangeContentBlockExists = shelfResultBottom?.some(
    (contentItem) => getCartridgeType(contentItem['@type']) === 'SMMY'
  );
  const handleBackButton = useLatestCallback(() => {
    sessionStorage.setItem('returnToSearchResults', 'true');
  });

  useEffect(() => {
    return () => {
      const skuIds =
        shelfData?.shelfParts.map((part) => {
          return part.skuNumber.toString();
        }) ?? [];
      if (enable24ProductView) {
        queryClient.removeQueries({ queryKey: ['productSkuDetails'] });
      } else {
        queryClient.removeQueries(
          getProductSkuDetailsKey({
            skuIds,
            vehicleId: catalogVehicleId,
            storeNumber,
            isNewDealsApiEnabled,
          })
        );
      }
    };
    // This should only be run on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const shelfPageBodyCMSContent = plpCMSData?.shelfpagebody;
  const shelfHeaderCMSContent = plpCMSData?.shelfresultheader;

  return (
    <div ref={mainShelfRef}>
      {isMobileFilterOpen && (
        <FilterMobileDrawer
          staticFilterData={brandShelfData?.staticNavigation ?? shelfLeftNav?.[0]?.staticNavigation}
          dynamicFilterData={shelfLeftNav?.[0]?.dynamicNavigation?.attributeNarrowingList}
          pageType="ProductShelf"
          showSpinner={shelfRequestFetching}
          onFilterSelect={handleFilter}
        />
      )}
      <div
        className={cx(
          azCommonStyles.mainContentContainer,
          styles.pageContent,
          styles.shelfContent,
          { [styles.placeholderHeight]: shelfRequestFetching }
        )}
      >
        {isMiniCartFlyoutEnabled ? (
          <div>{shelfRequestSuccess && renderMiniCart()}</div>
        ) : (
          <div>{shelfRequestSuccess && isDesktop && renderMiniCart()}</div>
        )}
        {isCSProductListingPageEnabled && shelfHeaderCMSContent?.length && (
          <CMSShelfResultHeaderModularHandler
            catLabel={shelfCatLabel}
            content={shelfHeaderCMSContent}
            seoTopCopy={seoTopCopy}
            shelfBreadcrumb={shelfBreadcrumb}
            showSEOData={!makeModelYearPageType}
            shortDescription={shortDescription}
            showBackButton={isNavigatedFromVisualFilter}
            handleClick={handleBackButton}
          />
        )}
        {!isCSProductListingPageEnabled && (
          <ShelfTopNavComponent
            makeModelYearPageType={makeModelYearPageType}
            shelfBreadcrumb={shelfBreadcrumb}
            catLabel={shelfCatLabel}
            shelfPart={shelfPageBody}
            shelfResultHeader={shelfResultHeader}
            shortDescription={shortDescription}
            seoTopCopy={seoTopCopy}
            showBackButton={isNavigatedFromVisualFilter}
          />
        )}

        {renderDealDrawer()}

        {shelfTippyTop?.length ? <ShelfPageTemplate content={shelfTippyTop} /> : null}
        {isMerchShelfPage && plpCMSData?.additional_attributes_merch_shelf_page?.seo ? (
          <CMSSeoTags seoData={plpCMSData.additional_attributes_merch_shelf_page.seo} />
        ) : null}
        {isMerchShelfPage && plpCMSData?.additional_attributes_merch_shelf_page?.index === false ? (
          <Head>
            <meta key="metaRobots" name="robots" content="noindex, nofollow" />
          </Head>
        ) : null}
        {seoMetadataInfo && renderShelfHelmetV2(seoMetadataInfo)}
        <div ref={shelfRef}>
          {showSkeleton || isPLPCMSDataFetching || productShelfResultsIsFetching ? (
            <ShelfSkeleton />
          ) : (
            <View className={styles.shelfNavAndResultsContainer}>
              <View className={styles.nav}>
                {shelfLeftNav?.length && !isCSProductListingPageEnabled ? (
                  <ShelfPageTemplate content={shelfLeftNav} onFilterSelect={handleFilter} />
                ) : null}
                {shelfLeftNav?.length && isCSProductListingPageEnabled && (isDesktop || isABot) ? (
                  <CMSShelfLeftNav content={shelfLeftNav} onFilterSelect={handleFilter} />
                ) : null}
              </View>
              <View className={styles.results} maxWidth={{ m: '100%' }}>
                {shelfPageBody?.length && !isCSProductListingPageEnabled ? (
                  <ShelfPageTemplate
                    ymmeRef={ymmeRef}
                    refQuickFilter={refQuickFilter}
                    content={shelfPageBody}
                    onFilterSelect={handleFilter}
                    handleDealClick={handleDealClick}
                  />
                ) : null}
                {isCSProductListingPageEnabled && shelfPageBodyCMSContent?.length ? (
                  <CMSPLPBodyModularHandler
                    pageType="shelf"
                    content={shelfPageBodyCMSContent}
                    refQuickFilter={refQuickFilter}
                    onFilterSelect={handleFilter}
                    handleDealClick={handleDealClick}
                  />
                ) : null}
              </View>
            </View>
          )}
        </div>
      </div>

      {!shelfRequestFetching && !isFetchingLocation && (
        <>
          {isCSProductListingPageEnabled && plpCMSData?.shelfresultbottom?.length ? (
            <CMSResultBottom
              content={plpCMSData.shelfresultbottom}
              onCertonaAddToCartClick={() => {
                setCertonaRecommendationAddedToCart(true);
              }}
              setAddedCertonaItemObject={setAddedCertonaItemObject}
              certonaRecommendations={certonaRecommendations}
              yearsTableData={{
                productId: productShelfResultsData?.productShelfResults?.productId,
                make: productShelfResultsData?.productShelfResults?.make ?? undefined,
                model: productShelfResultsData?.productShelfResults?.model ?? undefined,
                hasAssociatedPartType:
                  productShelfResultsData?.productShelfResults?.hasAssociatedPartType,
              }}
            />
          ) : null}
          {!isCSProductListingPageEnabled && shelfResultBottom?.length ? (
            yearsTableEnabled &&
            productShelfResultsData?.productShelfResults?.yearsTableEnableFlag ? (
              <ShelfResultBottom
                certonaRecommendations={certonaRecommendations}
                shelfResultBottom={shelfResultBottom}
                yearsTableData={{
                  productId: productShelfResultsData.productShelfResults.productId,
                  make: productShelfResultsData.productShelfResults.make ?? undefined,
                  model: productShelfResultsData.productShelfResults.model ?? undefined,
                  hasAssociatedPartType:
                    productShelfResultsData.productShelfResults.hasAssociatedPartType,
                }}
              />
            ) : (
              <ShelfResultBottom
                certonaRecommendations={certonaRecommendations}
                shelfResultBottom={shelfResultBottom}
              />
            )
          ) : null}
          {!isCSProductListingPageEnabled &&
            !!productShelfResultsData?.productShelfResults?.productId &&
            locale === countryCodes.us &&
            makeModelYearPageType &&
            !yearsRangeContentBlockExists &&
            yearsTableEnabled &&
            productShelfResultsData.productShelfResults.yearsTableEnableFlag && (
              <LazyYearsTable
                productId={productShelfResultsData.productShelfResults.productId}
                make={productShelfResultsData.productShelfResults.make ?? undefined}
                model={productShelfResultsData.productShelfResults.model ?? undefined}
                hasAssociatedPartType={
                  productShelfResultsData.productShelfResults.hasAssociatedPartType
                }
              />
            )}
          {reviews?.length ? <BazaarVoiceShelf reviews={reviews} /> : null}
          {!isCSProductListingPageEnabled &&
            shelfProductId &&
            locale === countryCodes.us &&
            !makeModelYearPageType && (
              <FeatureFlag flag="DLM_SHELF_ENABLED">
                <DynamicLinks seoRepositoryId={shelfProductId} />
              </FeatureFlag>
            )}
          {!isCSProductListingPageEnabled &&
            shelfProductId &&
            locale === countryCodes.us &&
            makeModelYearPageType && (
              <FeatureFlag flag="DLM_YMM_SHELF_ENABLED">
                <VehicleDynamicLinks seoRepositoryId={shelfProductId} />
              </FeatureFlag>
            )}
          {!isBrandShelfPage(router.asPath) && !isMerchShelfPage && <PreFooter />}
          {shelfHead?.length ? <HeadTemplate content={shelfHead} /> : null}
        </>
      )}
      <div className={styles.miniCartPopup}>
        <Drawer
          data-testid="mini-cart-flyout"
          anchor={'right'}
          open={showCertonaMiniCartFlyoutModal}
          onClose={toggleCertonaMiniCartFlyout}
          PaperProps={{ className: styles.customPaperDrawer }}
          drawerRef={lazyMiniCartModalRef}
        >
          <TrapFocus open={showCertonaMiniCartFlyoutModal}>
            <div className={styles.miniCartFlyoutDialog} role="dialog" aria-modal="true">
              <Suspense>
                {showCertonaMiniCartFlyoutModal && addedCertonaItemObject && (
                  <LazyMiniCartFlyoutComp
                    handleClose={toggleCertonaMiniCartFlyout}
                    isModalOpen={showCertonaMiniCartFlyoutModal}
                    image={addedCertonaItemObject.productImage}
                    successData={addedCertonaItemObject.cartSuccessData}
                    productDetails={{
                      productName: addedCertonaItemObject.productDetails.productName,
                      certonaItemAddedTocart:
                        certonaRecommendationAddedToCart && isShelfCertonaAddToCartEnabled,
                      productPartType: addedCertonaItemObject.productDetails.partType,
                      productId: addedCertonaItemObject.productDetails.productId,
                      productAvailabilityInfo: {
                        aZBadgesFlagVO: null,
                        dealsInfo: undefined,
                        skuPricingAndAvailability: undefined,
                      },
                    }}
                    miniCartData={miniCartMap}
                    closeStoreModal={closeStoreModal}
                    cartUrl={addedCertonaItemObject.submitToCart}
                    productTitle={addedCertonaItemObject.productTitle}
                    pageName={addedCertonaItemObject.pageName}
                    orderDetailsFromState={orderDetailsFromState}
                    skuId={addedCertonaItemObject.productDetails.skuId}
                    parentRef={lazyMiniCartModalRef}
                  />
                )}
              </Suspense>
            </div>
          </TrapFocus>
        </Drawer>
      </div>
    </div>
  );
};

function ShelfResultBottom({
  shelfResultBottom,
  certonaRecommendations,
  yearsTableData,
}: {
  shelfResultBottom:
    | [ShelfPageBodyItemGeneric, ShelfResultsBottomItemTwo]
    | Array<{ '@type': string }>;
  certonaRecommendations: CertonaRecommendation[];
  yearsTableData?: {
    productId?: string | null;
    make?: string;
    model?: string;
    hasAssociatedPartType?: boolean;
  };
}) {
  return (
    <div className={styles.shelfResultBottomContainer}>
      <ShelfPageTemplate
        content={shelfResultBottom}
        certonaRecommendations={certonaRecommendations}
        yearsTableData={yearsTableData}
      />
    </div>
  );
}
