/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import RatingsReview from '@/components/AZCustomComponent/RatingsReview';
import { Button } from '@/components/Button';
import Hidden from '@/components/Hidden';
import NextImage from '@/components/NextImage';
import { useLabel } from '@/hooks/useLabels';
import type { OldReview } from '../../interface';
import azCommonStyles from '@/theme/globals.module.scss';
import { SmartLink as Link } from '@/utils/smartLink';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import styles from './styles.module.scss';

dayjs.extend(relativeTime);

type Props = {
  review: OldReview;
};

const getRelativePath = (fullPath: string): string => {
  // for now, it's working for US website only, as we don't have reviews on MX
  const prod = 'autozone.com';
  return fullPath.includes('://') ? fullPath.split(prod)[1] : fullPath;
};

export const BVShelfCard = ({ review }: Props) => {
  const lblHeading = useLabel('label_reviews_for');
  const lblOverall = useLabel('label_overall');
  const lblViewAll = useLabel('label_view_all_reviews');
  const productPageUrl = getRelativePath(review.productPageUrl);

  return (
    <div
      className={`
        ${azCommonStyles['az-margin-top-4xs']}
        ${azCommonStyles['az-padding-xxs']}
        ${azCommonStyles['az-padding-bottom-m']}
        ${styles.bvCardContainer}`}
    >
      <p className={`${azCommonStyles['az-overline']} ${styles.bvCardTitle}`}>{lblHeading}</p>
      <div className={styles.bvCardHeader}>
        <div>
          <Link
            className={`
              ${azCommonStyles['az-subtitle-3-heavy']}
              ${azCommonStyles['az-margin-top-5xs']}
              ${azCommonStyles['az-margin-bottom-4xs']}
              ${styles.productTitle}`}
            to={productPageUrl}
          >
            {review.skuDescription}
          </Link>
          <div className={`${styles.ratingContainer} ${azCommonStyles['az-margin-bottom-4xs']}`}>
            <p
              className={`${azCommonStyles['az-caption']} ${styles.ratingText} ${azCommonStyles['az-margin-right-xxxs']}`}
            >
              {lblOverall}
            </p>
            <RatingsReview
              averageRating={review.averageOverallRating}
              totalReviews={review.totalReviewCount}
              hideAverageRatingNumber
            />
          </div>
        </div>
        <Button
          className={`
            ${azCommonStyles['az-padding-left-4xs']}
            ${azCommonStyles['az-padding-right-4xs']}
            ${styles.viewAllButton}`}
        >
          <Link
            className={`${azCommonStyles['az-body-2-regular']} ${styles.viewAllLabel}`}
            to={`${productPageUrl}#bv_PDPReviews`}
            scroll={false}
            samePage
          >
            {lblViewAll}
          </Link>
        </Button>
      </div>
      <hr
        className={`
          ${azCommonStyles['az-margin-top-m']}
          ${azCommonStyles['az-margin-bottom-m']}
          ${styles.bvCardHeaderSeparator}`}
      />
      <div className={styles.bvInfoContainer}>
        <Link to={productPageUrl}>
          <div className={styles.bvImage}>
            <NextImage src={review.imageUrl} alt={review.skuDescription} width={100} height={100} />
          </div>
        </Link>

        <div
          className={`${styles.bvInfo} ${styles.bvInfoText} ${azCommonStyles['az-margin-left-m']}`}
        >
          <p className={azCommonStyles['az-body-1-heavy']}>{review.reviewTitle}</p>
          <p className={styles.bvUser}>{review.userNickname}</p>
          <p
            className={`
              ${azCommonStyles['az-caption']}
              ${azCommonStyles['az-margin-top-5xs']}
              ${azCommonStyles['az-margin-bottom-4xs']}`}
          >
            {dayjs(new Date(review.submissionTime)).fromNow()}
          </p>
          <Hidden implementation="js" smDown>
            <p className={styles.bvText}>{review.reviewText}</p>
            <p className={azCommonStyles['az-margin-top-xxs']}>
              <b>{review.vehicleQuestion} </b>
              {review.vehicleAnswer}
            </p>
          </Hidden>
        </div>
      </div>
      <Hidden implementation="js" mdUp>
        <div className={`${styles.bvInfoText} ${azCommonStyles['az-margin-top-xxs']}`}>
          <p className={styles.bvText}>{review.reviewText}</p>
          <p className={azCommonStyles['az-margin-top-xxs']}>
            <b>{`${review.vehicleQuestion} `}</b>
            {review.vehicleAnswer}
          </p>
        </div>
      </Hidden>
    </div>
  );
};
