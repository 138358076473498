/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import { interpolateLabel } from '@/utils/labels/interpolateLabel';
import DeviceWrapper from '@/components/AZCustomComponent/DeviceWrapper';
import azCommonStyles from '@/theme/globals.module.scss';
import Hidden from '@/components/Hidden';
import styles from './CopyBlock.module.scss';
import { useProductList } from '../../../../context/useProductList';
import { useGetShelfPageRecords } from '../../../../hooks/useGetShelfPageRecords';
import Title from '../../../../../../components/AZCustomComponent/Title';
import { useLabel } from '@/hooks/useLabels';
import { useBrandProductData } from '@/features/brands/api/getProductBrandData';

type Props = {
  contentItem: {
    content: string;
    desktop: boolean;
    mobile: boolean;
    tablet: boolean;
  };
};

export function CopyBlock({ contentItem }: Props) {
  const { data: shelfData } = useProductList();
  const brandName = shelfData?.brandName;
  const shelfPageBody = shelfData?.shelfPageBody;
  const { data: brandProductData } = useBrandProductData();
  const about = useLabel('label_generic_about');
  const title = `${about} ${brandName ?? ''}`;
  const pageRecData = useGetShelfPageRecords(shelfPageBody);
  const totalNumRecs = brandProductData?.total ?? pageRecData?.totalNumRecs ?? 0;
  return (
    <DeviceWrapper
      desktop={contentItem.desktop || false}
      mobile={contentItem.mobile || false}
      tablet={contentItem.tablet || false}
    >
      <>
        <Hidden mdDown>
          <Title
            firstText={title}
            overRuleH1="h2"
            firstTextClass={azCommonStyles['az-margin-bottom-xxs']}
            variant="azTitleMedium"
          />
        </Hidden>
        <div
          className={cx(
            azCommonStyles['az-body-1-regular'],
            azCommonStyles['az-padding-right-l'],
            styles.copyBlockContent
          )}
        >
          {interpolateLabel(
            contentItem.content,
            {
              'Brand Name': brandName,
              '# of Products': totalNumRecs,
              'Part Type Name 1':
                (brandProductData?.records &&
                  brandProductData.records.length > 0 &&
                  brandProductData.records[0]?.description) ??
                (pageRecData?.records &&
                  pageRecData.records.length > 0 &&
                  pageRecData.records[0]?.name) ??
                '',
              'Part Type Name 2':
                (brandProductData?.records &&
                  brandProductData.records.length > 1 &&
                  brandProductData.records[1]?.description) ??
                (pageRecData?.records &&
                  pageRecData.records.length > 1 &&
                  pageRecData.records[1]?.name) ??
                '',
            },
            true
          )}
        </div>
      </>
    </DeviceWrapper>
  );
}
