/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import DeviceWrapper from '@/components/AZCustomComponent/DeviceWrapper';
import { useResponsiveImage } from '@/hooks/useResponsiveImage';
import { useRouter } from 'next/router';
import { useAppState } from '@/stores/AppState';
import { YMMEModal } from './YMMEModal';
import { saveUIState } from '@/stores/actions';
import type { CampaignData } from '../interface';

type Props = {
  campaignData: CampaignData;
};

export const CampaignModal = ({ campaignData }: Props) => {
  const router = useRouter();
  const { state, dispatch } = useAppState();
  const { campaignModalEnabled } = state || {};
  const { query = {} } = router || {};
  const { cmpid: queryStringCampaignId } = query;
  const { campaigns = [] } = campaignData || {};
  const ymmeCampaignData = campaigns.find(
    (campaign) => campaign.campaignId === queryStringCampaignId
  );

  const {
    mobile = false,
    tablet = false,
    desktop = false,
    bannerImage,
    bannerAltText,
    captionText,
    ctaButtonText,
  } = ymmeCampaignData || {};
  const responsiveImage = useResponsiveImage(bannerImage || '');
  const closeYmmeWidget = () => {
    dispatch(saveUIState({ campaignModalEnabled: false }));
  };
  return (
    <DeviceWrapper mobile={mobile} tablet={tablet} desktop={desktop}>
      <YMMEModal
        showYmme={ymmeCampaignData && campaignModalEnabled}
        closeYmmeWidget={closeYmmeWidget}
        onUpdateVehicleSuccess={closeYmmeWidget}
        pageType={'campaign'}
        bannerImage={responsiveImage}
        bannerAltText={bannerAltText}
        ymmeCaptionText={captionText}
        ctaButtonText={ctaButtonText}
        campaignFlow
      />
    </DeviceWrapper>
  );
};
