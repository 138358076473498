/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { SmartLink as Link } from '../../../../utils/smartLink';
import azCommonStyles from '../../../../theme/globals.module.scss';
import type { RelatedColumProps } from '../types';
import { useLabel } from '@/hooks/useLabels';
import styles from './styles.module.scss';

function RelatedColum({ nameOfTheColum, relatedColumItem }: RelatedColumProps) {
  const [relatedItems, setRelatedItems] = useState(relatedColumItem);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (relatedColumItem && relatedColumItem.length >= 6) {
      const shortList = relatedColumItem?.slice(0, 5);
      setRelatedItems(shortList);
    } else {
      setRelatedItems(relatedColumItem);
    }
  }, [relatedColumItem]);
  useEffect(() => {
    if (showMore) {
      const longList = relatedColumItem?.slice(0, 11);
      setRelatedItems(longList);
    } else {
      const shortList = relatedColumItem?.slice(0, 5);
      setRelatedItems(shortList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMore]);
  const viewMoreLabel = useLabel('related_view_more_related_parts');
  const viewFewerLabel = useLabel('related_view_fewer_related_parts');
  return (
    <div className={styles.relatedColumContainer}>
      <h2 className={styles.relatedColumContainer_Title}>{nameOfTheColum.toUpperCase()}</h2>
      <ul>
        {relatedItems?.map((item) => (
          <li key={item.seoUrl} className={styles.relatedColumContainer_Item}>
            <Link
              to={{
                pathname: item.seoUrl,
              }}
              className={styles.relatedColumContainer_Item_Link}
              role="link"
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
      {relatedColumItem &&
        relatedColumItem.length >= 6 && ( // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            className={cx(azCommonStyles['az-body-2-heavy'], styles.relatedColumContainer_Button)}
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? viewFewerLabel : viewMoreLabel}
          </div>
        )}
    </div>
  );
}

export default RelatedColum;
