/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL, requestBaseURLGCPExtOrInt } from '@/config/serviceAPI';
import { GlobalConfig, useGlobalConfig } from '@/features/globalConfig';
import { getAxios } from '@/lib/axios';
import { AxiosInstance } from 'axios';
import { QueryClient } from '@tanstack/react-query';
import { ReviewsResponse, TopReviewsData } from '../interface';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { createQuery } from '@/utils/createReactQuery';
import { GetTopReviewsUsingGetParamsModel } from '@/api/types/seo-data-types';

type Options = {
  minimumReviewRating: number | undefined;
  pageType: 'ShopByMake' | 'ShopByModel' | 'ShopByYear' | undefined | null;
  partTypeId: string | undefined;
  vehicleMake: string | undefined;
  vehicleModel: string | undefined;
  vehicleYear: string | undefined;
};

type TopReviewsQueryOptions = {
  locale: Locale;
  minimumReviewRating: number | undefined;
  partTypeId: string | undefined;
  vehicleMake: string | undefined;
  vehicleModel: string | undefined;
  vehicleYear: string | undefined;
  gcpUrlEnabled: boolean;
};

const URL = `${requestBaseURL}/external/product-discovery/seo-data/v1/reviews`;
const GCP_URL = `${requestBaseURLGCPExtOrInt}/sls/b2c/product-discovery-seo-data-bs/v2/reviews`;

const reviewsSelector = (data: ReviewsResponse): TopReviewsData =>
  data.map(
    ({
      averageOverallRating,
      reviewSubmitTime,
      itemDescription,
      itemId,
      itemImageUrl,
      productDetailsPageUrl,
      totalReviewCount,
      ...rest
    }) => ({
      averageOverallRating: String(averageOverallRating),
      imageUrl: itemImageUrl,
      productPageUrl: productDetailsPageUrl,
      skuDescription: itemDescription,
      skuId: String(itemId),
      submissionTime: reviewSubmitTime,
      totalReviewCount: String(totalReviewCount),
      ...rest,
    })
  );

const { useData: useProductTopReviews, prefetch: prefetchProductTopReviews } = createQuery<
  TopReviewsData,
  TopReviewsQueryOptions
>('topShelfReviews', async (options, axiosInstance) => getTopReviews(options, axiosInstance));

const getTopReviews = async (options: TopReviewsQueryOptions, axiosInstance?: AxiosInstance) => {
  const {
    locale,
    minimumReviewRating,
    partTypeId,
    vehicleMake,
    vehicleModel,
    vehicleYear,
    gcpUrlEnabled,
  } = options;
  const country = getCountryFromLocale(locale);
  const customerType = 'B2C';

  if (!vehicleMake) {
    throw new Error('Vehicle Make is required.');
  }

  if (!vehicleModel) {
    throw new Error('Vehicle Model is required.');
  }

  if (!partTypeId) {
    throw new Error('Product ID is required.');
  }

  const params: GetTopReviewsUsingGetParamsModel = {
    country,
    customerType,
    minimumReviewRating,
    productId: partTypeId,
    vehicleMake,
    vehicleModel,
    vehicleYear,
  };

  const response = await getAxios(axiosInstance).get<ReviewsResponse>(
    gcpUrlEnabled ? GCP_URL : URL,
    {
      params,
      ...(gcpUrlEnabled && { withCredentials: false }),
    }
  );
  return reviewsSelector(response.data);
};

const isEnabled = (
  { RR_TOP_AZRWEB_SHELF_ENABLED }: GlobalConfig,
  { pageType, vehicleMake, vehicleModel, partTypeId }: Options
) => {
  const [, isModelReviewsEnabled = false, isYearReviewsEnabled = false] =
    RR_TOP_AZRWEB_SHELF_ENABLED.split('-').map((it) => {
      const parsedData: unknown = JSON.parse(it);

      if (typeof parsedData === 'boolean') {
        return parsedData;
      }

      if (!parsedData) {
        return undefined;
      }
    });

  if (
    !vehicleMake ||
    !vehicleModel ||
    !partTypeId ||
    (pageType === 'ShopByModel' && !isModelReviewsEnabled) ||
    (pageType === 'ShopByYear' && !isYearReviewsEnabled)
  ) {
    return false;
  }

  return true;
};

export const useTopReviews = ({
  enabled = true,
  gcpUrlEnabled,
  ...options
}: Options & { enabled?: boolean; gcpUrlEnabled: boolean }) => {
  const locale = useLocale();
  const configResult = useGlobalConfig();

  return useProductTopReviews({
    enabled: enabled && configResult.isSuccess ? isEnabled(configResult.data, options) : false,
    locale,
    gcpUrlEnabled,
    ...options,
  });
};

export const prefetchTopReviews = async (
  axiosInstance: AxiosInstance,
  queryClient: QueryClient,
  config: GlobalConfig,
  options: Options,
  locale: Locale,
  gcpUrlEnabled: boolean
) => {
  if (!isEnabled(config, options)) {
    return;
  }
  const opts: TopReviewsQueryOptions = {
    locale,
    gcpUrlEnabled,
    ...options,
  };

  await prefetchProductTopReviews(queryClient, opts, axiosInstance);
};
